@import "variables";
@import "color";

.navigation {
  position: inherit !important;

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 0;
    z-index: 2;
    background-color: $color-primary;
  }

  ul.primary {
    margin: 0;

    > li {
      display: flex;
      flex-flow: row wrap;
      cursor: pointer;
      align-items: center;
      max-width: 100%;

      &:not(:last-child) {
        margin-right: $size-big;
      }

      a {
        flex-flow: column;
        align-items: flex-start;
        text-decoration: none;
        margin-bottom: 0;

        &.active-page {
          color: $color-secondary;

          &:hover {
            color: $color-secondary;
          }
        }
      }

      .nav-title {
        text-transform: uppercase;
        margin-bottom: 0;
        text-align: center;
        transition: 0.2s ease-in-out;

        &::before {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden;
        }
      }

      .nav-title.title-bold,
      > a.active-page > .nav-title:first-of-type {
        font-weight: 700;
        color: $color-secondary;
      }

      &:hover {
        > .nav-title,
        > a > .nav-title,
        > .nav-title.title-bold,
        > a > .nav-title.title-bold,
        > a.active-page > .nav-title:first-of-type {
          color: $color-secondary;
          font-weight: 700;
        }

        .nav-toggle-arrow span {
          background-color: $color-secondary;
        }
      }

      li .nav-title {
        text-align: left;
      }
    }
  }

  .drop-down {
    flex: 1 100%;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: $color-primary;
    z-index: 2;
    padding: $size-double 0;
    border-bottom: 1px solid $color-gray-border;
    border-top: 1px solid $color-gray-border;
    max-width: 100%;

    /* Scroll tab */
    & ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      padding: 3px;
    }

    & ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    & ::-webkit-scrollbar-thumb {
      background: #08315a;
    }

    & ::-webkit-scrollbar-thumb:hover {
      background: #ffed00;
    }

    > div {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      overflow-y: auto;
      max-height: 60vh;
      padding-bottom: 8px;
    }
  }

  /* Children menu */
  ul.secondary {
    flex-flow: row nowrap;

    > li {
      max-width: 329px;

      &:not(:last-child) {
        margin-right: $size;
      }
    }
  }

  .nav-img,
  .rim-app-img {
    border-radius: 4px;
    margin-bottom: $size-half;
  }

  .nav-text {
    color: $color-gray-text-heading;
    margin: $size-half 0 0 0;
    font-size: 15px;
    line-height: 1.6;
  }

  /* Rim application */
  .rim-app {
    margin-left: $size;
    min-height: 310px;
    max-width: 329px;

    .rim-text,
    .rim-link {
      text-transform: uppercase;
      font-size: 14px;
    }

    .rim-text {
      margin-bottom: $size
    }

    .rim-link-more {
      font-weight: 700;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start;

      svg {
        margin-left: $size-half;
        stroke: $color-secondary;
        transition: 0.3s ease-in-out;
      }
    }

    &:hover .rim-link-more svg {
      stroke: $color-tertiary
    }

    &:hover .rim-text {
      color: $color-secondary
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navigation .drop-down {
    top: 151px;
  }
}

@media only screen and (max-width: 1200px) {
  .navigation ul.primary > li {
    padding: $size-small 0;
  }
}

@media only screen and (max-width: 992px) {
  .navigation {
    padding-top: $size-extra-big;

    ul.primary {
      flex-flow: column;
      overflow-x: hidden;

      > li,
      > li:not(:last-child) {
        margin: 0 0 $size-extra-big 0;
        padding: 0;

        a {
          margin-bottom: 0;
        }
      }

      > li.opacity {
        .nav-title,
        .nav-toggle-arrow {
          opacity: 0.3;
        }
      }

      > li:hover > .nav-title,
      > li:hover > a.active-page > .nav-title {
        color: $color-secondary;
      }

      > li:hover > .nav-toggle-arrow > span {
        background-color: $color-secondary;
      }
    }

    .drop-down {
      position: static;
      padding: $size-double 0 0 0;
      border: 0;
    }

    ul.secondary {
      margin: 0;

      > li a .nav-title {
        margin-bottom: $size-half;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .navigation {
    padding-top: $size-big;

    ul.primary > li:not(:last-child) {
      margin-bottom: $size-big;
    }

    ul.secondary {
      flex-flow: column;

      > li {
        margin-bottom: $size-big;

        &:last-child {
          margin-bottom: 0;
        }

        a .nav-title {
          margin-bottom: 0;
        }

        .nav-img,
        .nav-text {
          display: none;
        }
      }
    }

    .rim-app {
      margin: $size-big $size 0 0;
      min-height: auto;

      .rim-app-img,
      .rim-text,
      .rim-link svg {
        display: none;
      }

      .rim-link {
        font-weight: 400;
      }
    }

    .drop-down {
      padding-top: $size-big;
      padding-bottom: 0;

      > div {
        flex-flow: column;
        overflow: visible;
      }
    }
  }

}
