.gallery-small {
  display: flex;
  flex-flow: row nowrap;

  @media only screen and (max-width: 992px) {
    border-radius: 4px;
    overflow: hidden;

    .space-column,
    .space {
      display: none;
    }
  }

  > div:nth-child(1) {
    flex: 1 50%;
  }

  > div:nth-child(2) {
    flex: 16px;
  }

  > div:nth-child(3) {
    flex: 1 50%;
    display: flex;
    flex-flow: column;

    > div:nth-child(1) {
      flex: 1 50%;
    }

    > div:nth-child(2) {
      height: 16px;
    }

    > div:nth-child(3) {
      flex: 1 50%;
    }

    > div:nth-child(4) {
      display: none;
    }
  }

  img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media only screen and (max-width: 992px) {
      border-radius: 0;
    }
  }
}
