@import '../base/color';
@import '../base/variables';

/* Components */
@import "../components/brandimages";

/* Site mottos */
.mottos {
  padding-top: $size-extra-big;
  padding-bottom: $size-big;

  > div *:first-child {
    margin-top: 0;
  }

  > div *:last-child {
    margin-bottom: 0;
  }

  > div:last-child p {
    color: $color-secondary-opacity;
    text-align: right;
  }
}

/* Error message */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Home configurator */
.home-configurator {
  height: calc(100vh - 293px);
  max-height: 728px;
  min-height: 400px;
  overflow: hidden;
}

/* Partners */
.partners {
  background-color: $color-gray-light;
  position: relative;
  min-height: 80px;

  .partner-img {
    position: absolute;

    img {
      opacity: 0.3;
      transition: 0.3s ease-in-out;

      &:hover,
      &:hover {
        opacity: 0.8;
      }
    }
  }

  .partner-img:first-child {
    left: 20.5%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .partner-img:last-child {
    right: 28%;
    top: 50%;
    transform: translate(50%, -50%);
  }
}

/* Find form */
.find-form {
  position: relative;
  margin-top: $size-big;
  margin-bottom: $size-big;

  .form-text {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: $size;
    width: 100%;
  }

  .search-input,
  .secondary-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    img {
      margin-right: $size-half;
    }

    input {
      border: 0;
      background-color: transparent;
      width: 100%;

      &:focus {
        outline: none;
      }
    }
  }

  .secondary-button input {
    cursor: pointer;
    text-align: center;
    transition: 0.2s ease-in-out;

    &::before {
      display: block;
      content: attr(value);
      font-weight: 700;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &:hover {
      font-weight: 700;
    }
  }

  .search-heading > *:last-child {
    margin-bottom: 0;
  }

  .search-input {
    padding: $size-half $size;
    box-shadow: 0 4px 42px rgba(0, 0, 0, 0.1);
    margin-top: $size-big;
    margin-bottom: $size-big;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    background-color: $color-primary;
    border: 0;
    border-radius: 100px;
    width: 387px;

    input {
      color: $color-secondary-opacity;
    }
  }

  .secondary-button input {
    text-transform: uppercase;
  }

  .form-background {
    height: 464px;
    width: 100%;
    object-fit: cover;
  }
}

/* Youtube video */
.youtube-video {
  margin-bottom: $size-big;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: 30%;

  iframe {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}

/* Grid view */
.grid-field {
  background-color: $color-gray-background;
  padding: ($size-extra-big + $size-double) 0 ($size-extra-big + $size-double) *
    2 0;

  .heading {
    text-align: center;
    margin-bottom: $size-extra-big + $size-double;
  }

  .grid {
    align-items: center;
    justify-content: space-between;

    .grid-form {
      padding: 8px;

      > div:first-child {
        background-color: #c4c4c4;
        height: 210px;
      }

      > div:last-child {
        background-color: #ffffff;
        padding: 18px;

        > *:first-child {
          margin-top: 0;
        }

        > *:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 1920px) {
  /* Partners */
  .partners {
    .partner-img:first-child {
      left: 21.5%;
    }

    .partner-img:last-child {
      right: 28%;
    }
  }

  /* Youtube video */
  .youtube-video {
    padding-bottom: 40%;
  }
}

@media only screen and (max-width: 1600px) {
  /* Partners */
  .partners {
    .partner-img:first-child {
      left: 22%;
    }

    .partner-img:last-child {
      right: 28.5%;
    }
  }
}

@media only screen and (max-width: 1440px) {
  /* Youtube video */
  .youtube-video {
    padding-bottom: 53%;
  }

  ///* Partners */
  //.partners {
  //  .partner-img:first-child {
  //    left: 24%;
  //  }
  //
  //  .partner-img:last-child {
  //    right: 30.5%;
  //  }
  //}
}

@media only screen and (max-width: 1100px) {
  /* Partners */
  .partners {
    min-height: 60px;

    img {
      max-height: 40px;
      max-width: 255px;
    }

    .partner-img:first-child {
      left: 25%;
    }

    .partner-img:last-child {
      right: 31%;
    }
  }
}

@media only screen and (max-width: 992px) {
  /* Home configurator */
  .home-configurator {
    height: calc(100vh - 91px);
    min-height: 475px;
    max-height: 800px;
  }

  /* Partners */
  .partners {
    .partner-img:first-child {
      left: 21%;
    }

    .partner-img:last-child {
      right: 26%;
    }
  }

  /* Find form, YouTube video, Grid video */
  .find-form,
  .youtube-video,
  .grid-field {
    margin: 0;
  }

  .grid-field {
    padding: $size-extra-big 0 $size-extra-big + $size-double 0;

    .heading {
      margin-bottom: $size-extra-big;
    }
  }
}

@media only screen and (max-width: 991px) {
  /* Site mottos */
  .mottos {
    padding: $size 0;

    div > div:first-child {
      margin-bottom: $size;
    }

    > div:last-child p {
      text-align: left;
    }
  }
}

@media only screen and (max-width: 768px) {
  /* Partners */
  .partners {
    min-height: auto;

    .wrap {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
      padding: $size-half 0;
    }

    .partner-img:first-child,
    .partner-img:last-child {
      position: unset;
      top: unset;
      left: unset;
      right: unset;
      transform: unset;
    }
  }

  /* Home configurator */
  .home-configurator {
   height: calc(100vh - 85px);
  }
}

@media only screen and (max-width: 650px) {
  /* Find form */
  .find-form .form-text {
    .search-input,
    form {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 576px) {
  /* Partners */
  .partners img {
    max-height: 40px;
    max-width: 90px;
  }

  .grid-field {
    padding-top: $size-double;
  }

  /* Home configurator */
  .home-configurator {
   height: calc(100vh - 73px);
   min-height: 550px;
  }
}

@media only screen and (max-width: 400px) {
  /* Design images*/
  .design-images {
    .img-btn:first-child a {
      top: $size;
      left: $size-half;
      bottom: auto;
      transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(0, 0);
    }

    .img-btn:last-child a {
      bottom: $size;
      right: $size-half;
      transform: translate(0, 0);
      -webkit-transform: translate(0, 0);
      -ms-transform: translate(-50%, 0);
    }
  }
}
