@import 'variables';
@import 'color';
@import 'changing-fonts.scss';

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-Regular.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-SemiBold.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-Bold.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/Roboto-Regular.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/Roboto-Bold.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/RobotoCondensed-Regular.woff2) format('woff2');
  font-display: swap;
}
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/RobotoCondensed-Bold.woff2) format('woff2');
  font-display: swap;
}

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 0;
  margin: 0;
}

@font-face {
  font-family: 'Commando';
  src: url('/assets/fonts/commando/Commando.eot') format('eot'),
  url('/assets/fonts/commando/Commando.ttf') format('ttf'),
  url('/assets/fonts/commando/Commando.woff') format('woff'),
url('/assets/fonts/commando/Commando.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

.featured-image-heading,
.carousel-slide .slide-heading,
.featured-image-news-heading {
  font-family: 'Commando', 'Roboto Condensed', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 $size-small 0;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.3;
  word-break: break-word;
}

.brand-page {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: $main-brand-font, 'Roboto Condensed', sans-serif !important;
  }
}

input,
select,
button {
  font-family: 'Open Sans', sans-serif !important;
  word-break: break-word;
}

p {
  margin: 0 0 $size 0;
  line-height: 1.5;
}

p, span {
  word-break: break-word;
}

div {
  line-height: 1.3;
}

ul li,
ol li {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
  font-size: 15px;

  p {
    margin: 0;
  }
}

ul li::marker {
  color: $color-secondary;
}

span,
a,
table {
  line-height: 1.3;
}

h1,
h1 * {
  font-size: 32px;
}

.dinNext h1,
.dinNext h1 * {
  font-size: 34px;
}

.neueMontreal h1,
.neueMontreal h1 * {
  font-size: 28px;
}

h1.featured-image-heading,
h1.featured-image-heading * {
  font-size: 86px;
}

h1.slide-heading,
h1.slide-heading * {
  font-size: 78px;
}

h1.banner-heading,
h1.banner-heading * {
  font-size: 36px;
  line-height: 1.1;
}

h2,
h2 * {
  font-size: 28px;
}


.dinNext h2,
.dinNext h2 * {
  font-size: 30px;
}

.neueMontreal h2,
.neueMontreal h2 * {
  font-size: 23px;
}

h2.slide-heading,
h2.slide-heading *,
h2.error-heading {
  font-size: 53px;
}

h3,
h3 *,
ul.secondary .nav-title,
ul.secondary .nav-title * {
  font-size: 24px;
}

.dinNext h3,
.dinNext h3 * {
  font-size: 26px;
}

.neueMontreal h3,
.neueMontreal h3 * {
  font-size: 19px;
}

h3.slide-heading,
h3.slide-heading *,
h3.error-heading {
  font-size: 32px;
}

h4,
h4 * {
  font-size: 20px;
}

.dinNext h4,
.dinNext h4 * {
  font-size: 24px;
}

.neueMontreal h4,
.neueMontreal h4 * {
  font-size: 18px;
}

h5,
h5 * {
  font-size: 17px;
}

h6,
h6 * {
  font-size: 14px;
}

img {
  margin-bottom: -4px;
}

@media only screen and (max-width: 1100px) {
  h1.slide-heading,
  h1.slide-heading * {
    font-size: 50px;
  }

  h1.banner-heading,
  h1.banner-heading * {
    font-size: 32px;
  }

  h2.slide-heading,
  h2.slide-heading *,
  h2.error-heading {
    font-size: 40px;
  }

  h3.slide-heading,
  h3.slide-heading *,
  h3.error-heading {
    font-size: 28px;
  }
}

@media only screen and (max-width: 992px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 $size-small 0;
    font-weight: 400;
    text-transform: uppercase;
  }

  h1,
  h1 * {
    font-size: 28px;
  }

  h1.featured-image-heading,
  h1.featured-image-heading *{
    font-size: 70px;
  }

  h2,
  h2 *,
  ul.primary .nav-title,
  ul.primary .nav-title * {
    font-size: 24px;
  }

  h3,
  h3 * {
    font-size: 20px;
  }

  h4,
  h4 * {
    font-size: 18px;
  }

  h5,
  h5 * {
    font-size: 17px;
  }

  h6,
  h6 *,
  ul.secondary .nav-title,
  ul.secondary .nav-title * {
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 $size-small 0;
    font-weight: 400;
    text-transform: uppercase;
  }

  h1,
  h1 * {
    font-size: 24px;
  }

  h1.featured-image-heading,
  h1.featured-image-heading * {
    font-size: 60px;
  }

  h2,
  h2 *,
  ul.primary .nav-title,
  ul.primary .nav-title * {
    font-size: 22px;
  }

  h3,
  h3 *,
  ul.secondary .nav-title,
  ul.secondary .nav-title * {
    font-size: 20px;
  }

  h4,
  h4 * {
    font-size: 18px;
  }

  h5,
  h5 * {
    font-size: 17px;
  }

  h6,
  h6 *,
  ul.secondary .nav-title,
  ul.secondary .nav-title * {
    font-size: 14px;
  }
}

@media only screen and (max-width: 576px) {
  h1.slide-heading,
  h1.slide-heading * {
    font-size: 32px;
  }

  h1.featured-image-heading,
  h1.featured-image-heading * {
    font-size: 50px;
  }

  h1.banner-heading,
  h1.banner-heading * {
    font-size: 22px;
  }

  h2.slide-heading,
  h2.slide-heading *,
  h2.error-heading {
    font-size: 25px;
  }

  h3.slide-heading,
  h3.slide-heading *,
  h3.error-heading {
    font-size: 20px;
  }
}
