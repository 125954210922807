.featured-image {
  position: relative;

  img,
  &-media {
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 240px);
    max-height: 800px;
    min-height: 400px;
    overflow: hidden;

    @media only screen and (max-width: 576px) {
      height: calc(100vh - 135px);
    }

    @media only screen and (max-width: 992px) {
      height: calc(100vh - 180px);
      max-height: 580px;
    }
  }

  .featured-content {
    bottom: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: $size-extra-big;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;

    &.hasvideo {
      @media screen and (min-width: 996px) {
        top: 50%;
        transform: translateY(-50%);
      }
    }

    @media screen and (min-width: 576px) {
      bottom: $size-double;
    }

    @media screen and (min-width: 996px) {
      top: 72px;
      bottom: auto;
    }

    h2 {
      font-size: 64px;
      line-height: 1;

      @media screen and (min-width: 996px) {
        font-size: 100px;
      }

      @media screen and (min-width: 1280px) {
        font-size: 120px;
      }
    }

    &-inner {
      &.center {
        .featured-image-heading {
          max-width: 100%;
          margin-right: auto;
          margin-left: auto;

          @media screen and (min-width: 996px) {
            max-width: 70%;
          }
        }

        h2 {
          text-align: center;
        }
      }
    }
  }

  &-heading {
    @media screen and (min-width: 996px) {
      text-align: left;
      max-width: 800px;
    }
  }

  &-text {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    height: 100%;

    @media only screen and (max-width: 576px) {
      bottom: $size;
    }

    @media only screen and (max-width: 992px) {
      bottom: $size-double;
    }

    @media only screen and (max-width: 1440px) {
      left: 0;
      transform: translate(0);
      -webkit-transform: translate(0);
      -ms-transform: translate(0);
    }

    p {
      max-width: 325px;
      line-height: 1.65;
      margin-bottom: 0;
    }
  }
}
