@import "../base/variables";

.info-featured-image {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    object-fit: cover;
    height: 200px;
  }

  h1 {
    position: absolute;
    margin: 0;
    font-weight: 700;
    text-align: center;
  }
}

.info-content {
  padding-top: $size-extra-big + $size-double;
  padding-bottom: $size-extra-big + $size-double;
}

@media only screen and (max-width: 576px) {
  .info-content {
    padding-top: $size-extra-big;
    padding-bottom: $size-extra-big;
  }
}



