@import 'variables';

main {
  max-width: 2560px;
  margin: 0 auto;
}

.wrap,
.wrap-menu {
  max-width: 1360px;
  margin: 0 auto;
}

.wrap-menu {
  margin: 0 auto $size-big auto;
}

.wrap-full {
  max-width: 100%;
  margin-right: 40px;
  margin-left: 40px;
}

.wrap-small {
  max-width: 700px;
  margin: 0 auto;
}

.wrap-inner {
  width: 1360px;
  margin: 0 auto;
}

@media only screen and (max-width: 1440px) {
  .wrap {
    margin: 0 $size-extra-big;
    max-width: 100%;
  }

  .wrap-menu {
    margin: 0 $size-extra-big $size-big $size-extra-big;
    max-width: 100%;
  }

  .wrap-inner {
    width: 100%;
    margin: 0;
    padding: 0 $size-extra-big;
  }
}

@media only screen and (max-width: 992px) {
  .wrap {
    margin: 0 $size-big;
  }

  .wrap-menu,
  .wrap-inner {
    padding: 0 $size-big;
    margin: 0;
  }
}

@media only screen and (max-width: 748px) {
  .wrap-small {
    width: 100%;
    padding: 0 $size-big;
  }
}

@media only screen and (max-width: 576px) {
  .wrap {
    margin: 0 $size;
  }

  .wrap-small {
    padding: 0 $size;
  }

  .wrap-menu,
  .wrap-inner {
    padding: 0 $size;
    margin: 0;
  }
}
