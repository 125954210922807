.video {
  &-heading {
    margin-bottom: $size-double;
  }

  &-content {
    iframe {
      border: 0;
      width: 100%;
      height: calc(100vw/1.77);
    }
  }
}
