@import "color";
@import "variables";

/* Main carousel dots color options */
.main-carousel {
  &.darkColor .slick-dots li {
    button:before {
      color: $color-secondary;
    }

    &.slick-active button:before {
      color: $color-secondary;
    }
  }

  &.lightColor .slick-dots li {
    button:before {
      color: $color-primary;
    }

    &.slick-active button:before {
      color: $color-primary;
    }
  }
}

/* General style */
.main-carousel,
.cards-carousel {
  position: relative;

  /* Slick buttons preview and next slider */
  button.slick-prev,
  button.slick-next {
    background-color: $color-primary;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    height: 32px;
    width: 32px;
    margin: 7px;
    cursor: pointer;
    z-index: 1;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    transition: 0.3s ease-in-out;

    &:hover {
      background-color: $color-tertiary;
    }
  }

  button.slick-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDEzTDEgN0w3IDEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
    left: $size-extra-big;
    transform: translate(-50%, 0);
  }

  button.slick-next {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjk5OTk5OSAxM0w3IDdMMSAxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
    right: $size-extra-big;
    transform: translate(50%, 0);
  }

  /* Slick dots */
  .slick-dots {
    position: absolute;
    bottom: $size-extra-big;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
        border: 0;
        background: transparent;
        display: block;
        height: 10px;
        width: 10px;
        outline: none;
        line-height: 0;
        font-size: 0;
        color: transparent;
        padding: 5px;
        cursor: pointer;

        &:hover, &:focus {
          outline: none;

          &:before {
            opacity: 1;
          }
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "\2022";
          width: 10px;
          height: 10px;
          font-size: 34px;
          line-height: 20px;
          text-align: center;
          color: $color-secondary;
          opacity: 0.2;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &.slick-active button:before {
        color: $color-secondary;
        opacity: 1;
      }
    }
  }
}

/* Main carousel */
.main-carousel {
  picture img {
    width: 100%;
    height: calc(100vh - 151px);
    min-height: 400px;
    max-height: 800px;
    object-fit: cover;
    object-position: 50% 60%;
  }

  .carousel-slide {
    position: relative;
  }

  .slider-content {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    padding-top: $size-double;
    padding-bottom: $size-double;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    align-items: flex-start;

    .top-content {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      width: 100%;
      margin-bottom: $size;

      img {
        margin-right: 13%;
        margin-bottom: $size-extra-big;
        max-width: 200px;
      }
    }

    .bottom-content {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: space-between;

      > *:last-child {
        margin-bottom: 0;
      }
    }

    h1.slide-heading {
      font-weight: 700;
    }

    h1, h2, h3 {
      line-height: 1;
      margin: 0;
    }

    h2 {
      margin-left: $size-extra-big;
    }

    h3 {
      margin-bottom: 16px;
      max-width: 440px;
    }

    .slide-text {
      max-width: 380px;
      line-height: 1.65;
      margin-bottom: 16px;
    }
  }
}

/* Cards carousel*/
.cards-carousel {
  margin-bottom: $size-extra-big;

  picture img {
    max-height: 552px;
    max-width: 100%;
    border-radius: 4px;
  }

  /* Sliders */
  .slick-slide {
    margin: 0 $size-big $size-big 0;
  }

  .slick-track {
    display: flex;
    align-items: center;
  }

  /* Slick dots */
  .slick-dots {
    bottom: -10px;
  }

  /* Slick buttons preview and next slider */
  button.slick-prev,
  button.slick-next {
    top: 43%;
  }

  &-thumbnails {
    margin: 0 auto;
    text-align: center;

    &-item {
      background-color: $color-secondary;
      border-radius: 5px;
      display: inline-block;
      height: 90px;
      overflow: hidden;
      width: 130px;

      &:hover {
        img {
          opacity: 0.5;
        }
      }

      img {
        border-radius: 5px;
        height: auto;
        object-fit: cover;
        transition: all 0.2s ease-in-out;
        width: 100%;
      }
    }

    .slick-slide {
      margin-right: 24px;
    }
  }
}

/* Page Main Color Options */
.blueColor,
.redColor {
  .main-carousel,
  .cards-carousel {
    /* Slick buttons preview and next slider */
    button.slick-prev:hover {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDEzTDEgN0w3IDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
    }

    button.slick-next:hover {
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjk5OTk5OSAxM0w3IDdMMSAxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
    }
  }
}
.blueColor {
  .main-carousel,
  .cards-carousel {
    /* Slick buttons preview and next slider */
    button.slick-prev,
    button.slick-next {
      &:hover {
        background-color: $color-blue;
      }
    }
  }
}

.redColor {
  .main-carousel,
  .cards-carousel {
    /* Slick buttons preview and next slider */
    button.slick-prev,
    button.slick-next {
      &:hover {
        background-color: $color-red;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .cards-carousel picture img {
    max-height: 400px;
  }

  .main-carousel {
    picture img {
      height: calc(100vh - 88px);
      max-height: 580px;
    }

    /* Slick dots */
    .slick-dots {
      bottom: $size-double;
    }

  }
}

@media only screen and (max-width: 768px) {
  .cards-carousel {
    .slick-slide {
      margin: 0 $size-half $size-big 0;
    }

    picture img {
      max-height: 328px;
    }
  }

  .main-carousel .slider-content {
    .top-content img {
        max-width: 140px;
    }

    > div div {
      flex: 1 100%;
    }

    h2 {
      margin-left: $size-big;
    }

    /* Slick dots */
    .slick-dots {
      bottom: $size-big;
    }
  }

}

@media only screen and (max-width: 576px) {
  .main-carousel {
    picture img {
      height: calc(100vh - 72px);
    }

    /* Slick dots */
    .slick-dots {
      bottom: $size;
    }
  }
}
