@import 'variables';
@import 'color';

button {
  cursor: pointer;
  border: 0;
  color: $color-secondary-opacity;
  background-color: transparent;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  padding: 1px $size-half;
  border-radius: 45px;
  word-break: break-word;
  font-family: 'Open Sans', sans-serif;
  transition: 0.3s ease-in-out;

  &:focus,
  &:active {
    outline: none;
  }
}

.primary-button,
.secondary-button {
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 45px;
  font-size: 14px;
  background-color: transparent;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  word-break: break-word;
  transition: 0.3s ease-in-out;

  &:focus,
  &:active {
    outline: none;
    transform: translateY(2px);
  }
}

.primary-button,
.primary-button:visited {
  border: 2px solid;
  padding: $size-half $size;
  min-width: 204px;
  max-width: 330px;
  min-height: 48px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: $size auto 0 auto;

  svg,
  img {
    margin-left: $size-half;
    fill: transparent;
    transition: 0.3s ease-in-out;
  }
}

.primary-button,
.primary-button:visited,
  /* Page Main Color Options */
.brand-page.blueColor .catalog-button,
.brand-page.blueColor .catalog-button:visited {
  border-color: $color-blue;
  background-color: $color-blue;
  color: $color-primary;

  &:hover {
    color: $color-blue;
    background-color: $color-primary;
  }

  svg,
  img {
    stroke: $color-primary;
    fill: transparent;
  }

  &:hover svg {
    stroke: $color-blue;
  }
}

.primary-button.blue-text,
.primary-button.blue-text:visited {
  border-color: $color-primary;
  background-color: $color-primary;
  color: $color-blue;
  margin: 0;

  &:hover {
    background-color: $color-blue;
    color: $color-primary;
  }
}

.primary-button.white-border,
.primary-button.white-border:visited {
  border-color: $color-primary;
  background-color: transparent;
  color: $color-primary;
  margin: 0;

  &:hover {
    color: $color-secondary;
    background-color: $color-primary;
  }
}

.primary-button.white-bg,
.primary-button.white-bg:visited {
  border-color: $color-primary;
  background-color: $color-primary;
  color: $color-secondary;
  margin: 0;

  &:hover {
    color: $color-primary;
    background-color: transparent;
  }
}

.primary-button.black-border,
.primary-button.black-border:visited {
  border-color: $color-secondary;
  background-color: transparent;
  color: $color-secondary;
  margin: 0;

  &:hover {
    color: $color-primary;
    background-color: $color-secondary;
  }
}

.primary-button.yellow-black-border,
.primary-button.black-border:visited {
  border-color: $color-secondary;
  background-color: transparent;
  color: $color-secondary;
  margin: 0;

  &:hover {
    color: $color-tertiary;
    background-color: $color-secondary;
  }
}

.primary-button.red,
.primary-button.red:visited,
  /* Page Main Color Options */
.brand-page.redColor .catalog-button,
.brand-page.redColor .catalog-button:visited {
  border-color: $color-red;
  background-color: $color-red;
  color: $color-primary;

  &:hover {
    background-color: $color-primary;
    color: $color-red;
  }

  svg,
  img {
    stroke: $color-primary;
  }

  &:hover svg {
    stroke: $color-red;
  }
}

.primary-button.red-text,
.primary-button.red-text:visited {
  border-color: $color-primary;
  background-color: $color-primary;
  color: $color-red;
  margin: 0;

  &:hover {
    border-color: $color-primary;
    background-color: $color-red;
    color: $color-primary;
  }
}

.primary-button.yellow,
.primary-button.yellow:visited {
  border-color: $color-tertiary;
  background-color: $color-tertiary;
  color: $color-secondary;
  font-size: 14px;
  font-weight: 400;
  min-height: 24px;
  padding: 1px $size-half;
  margin: 0;

  &:hover {
    border-color: $color-tertiary;
    background-color: $color-primary !important;
    color: $color-secondary !important;
  }
}

.primary-button.yellow.background-black,
.primary-button.yellow:visited {
  border-color: $color-tertiary;
  background-color: $color-tertiary;
  color: $color-secondary;
  font-size: 14px;
  font-weight: 700;
  min-height: 24px;
  padding: 1px $size-half;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  max-width: 200px;


  &:hover {
    border-color: $color-tertiary;
    background-color: $color-secondary;
    color: $color-primary;
  }
}

.primary-button.disruptor,
.primary-button.disruptor:visited {
  border-color: $color-light-blue;
  color: $color-primary;
  background-color: $color-light-blue;
  font-size: 14px;
  font-weight: 400;
  min-height: 24px;
  padding: 1px $size-half;
  margin: 0;

  &:hover {
    background-color: $color-primary;
    color: $color-gray-text-heading;
  }
}

.primary-button.black,
.primary-button.black:visited {
  border-color: $color-secondary;
  background-color: $color-secondary;
  color: $color-primary;
  padding: $size-half $size;
  min-height: 48px;
  margin: 0;

  &:hover {
    background-color: $color-primary;
    color: $color-secondary;
  }
}

.primary-button.small {
  min-width: 135px;
  padding: 1px 8px;
  min-height: 24px;
}

.secondary-button {
  padding: 0 $size-small;
  border: none;
  font-weight: 400;
}

.fit {
  max-width: 240px;
}

.fullWidth {
  max-width: 100%;
}

.nav-menu-btn {
  min-width: 24px;
  min-height: 20px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;

  span {
    display: block;
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: #000000;
    border-radius: 30%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2),
  span:nth-child(3) {
    top: 8px;
  }

  span:nth-child(4) {
    top: 16px;
  }

  &.open span:nth-child(1) {
    top: 18px;
    width: 0;
    left: 50%;
  }

  &.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  &.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  &.open span:nth-child(4) {
    top: 18px;
    width: 0;
    left: 50%;
  }
}

a.primary-button.yellow.background-grey,
a.primary-button.yellow.background-grey:visited {
  border-color: $color-tertiary;
  background-color: $color-tertiary;
  color: $color-secondary;
  font-size: 14px;
  font-weight: 600;
  min-height: 24px;
  padding: 1px $size-half;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center !important;
  max-width: 300px;


  &:hover {
    border-color: $color-tertiary;
    background-color: $color-gray-background-two;
    font-weight: 900;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  button,
  .button,
  .primary-button {
    padding: 13px;
  }
}

@media only screen and (max-width: 992px) {
  .primary-button {
    min-width: 204px;
    max-width: 330px;
  }

  .fit {
    max-width: 240px;
  }

  .fullWidth {
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .primary-button,
  .fit,
  .fullWidth {
    max-width: 100%;
  }
}
