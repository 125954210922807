$size-footer-margin: 144px;
$size-extra-big: 40px;
$size-double: 32px;
$size-big: 24px;
$size: 16px;
$size-half: 8px;
$size-small: 6px;

.laptop,
.tablet,
.mobile {
  display: none !important;
}

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important;
  }
}

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important;
  }

  .tablet {
    display: flex !important;
  }
}

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important;
  }

  .mobile {
    display: flex !important;
  }
}
