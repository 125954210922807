@import "../base/color";
@import "../base/variables";


/* Content fields with text and image */
.content-fields {
  .text-img-right,
  .text-img-left {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: $size-extra-big + $size-double;
    padding-bottom: $size-extra-big + $size-double;

    > div:first-child {
      margin-right: $size-extra-big + $size-double;
    }

    .field-image {
      flex: 1 50%;
      max-width: 50%;
    }

    .field-text {
      flex: 1 40%;
      max-width: 40%;

      h1:last-of-type,
      h2:last-of-type,
      h3:last-of-type,
      h4:last-of-type,
      h5:last-of-type,
      h6:last-of-type, {
        margin-bottom: $size-double;
      }
    }

    img {
      width: 100%;
      height: auto;
      border-radius: 4px;
    }

    h1 {
      text-align: center;
    }
  }

  .text-img-right {
    justify-content: flex-end;
  }

  .text-img-left {
    justify-content: flex-start;
  }
}



@media only screen and (max-width: 1440px) {
  /* Content fields with text and image */
  .content-fields {
    .text-img-right,
    .text-img-left {
      justify-content: space-between;

      > div:first-child {
        margin-right: $size-extra-big;
      }

      .field-image,
      .field-text {
        flex: 1 50%;
        max-width: 50%;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  /* Content fields with text and image */
  .content-fields {
    .text-img-right,
    .text-img-left {
      flex-flow: column;
      align-items: center;

      > div:first-child {
        margin: 0 0 $size-double 0;
      }

      .field-image,
      .field-text {
        flex: 1 100%;
        max-width: 100%;
      }

      .field-text {
        max-width: 560px;

        h1:last-of-type,
        h2:last-of-type,
        h3:last-of-type,
        h4:last-of-type,
        h5:last-of-type,
        h6:last-of-type, {
          margin-bottom: $size-big;
        }
      }
    }
  }

  /* Job offers */
  .jobs-field {
    flex-flow: row wrap;

    h3 {
      margin-bottom: $size-big;
    }

    > div {
      flex: 1 100%;

      &:first-child {
        margin: 0 0 $size-extra-big 0;
      }
    }

    .job-candidature .candidature {
      padding-bottom: $size-extra-big;
      margin-bottom: $size-extra-big;
    }
  }
}

@media only screen and (max-width: 576px) {
  /* Content fields with text and image */
  .content-fields {
    .text-img-right,
    .text-img-left {
      padding-top: $size-extra-big;
      padding-bottom: $size-extra-big;
    }
  }

  /* Job offers */
  .jobs-field {
    padding-top: $size-extra-big;
    padding-bottom: $size-extra-big;

    .job-candidature {
      .candidature {
        padding-bottom: $size-big;
        margin-bottom: $size-big;

        a {
          margin-top: $size-big;
        }
      }

      .contact-content {
        flex-flow: column;

        > div:first-child {
          margin: 0 0 $size 0;
        }
      }
    }
  }
}
