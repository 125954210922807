.configurator-page {
  min-height: calc(100vh - 153px);;
}

@media only screen and (max-width: 992px) {
  .configurator-page {
    min-height: calc(100vh - 92px);
  }
}

@media only screen and (max-width: 576px) {
  .configurator-page {
    min-height: auto;
  }
}

