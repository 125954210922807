@import "../base/color";
@import "../base/variables";

.not-found-page,
.error-page {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  animation: 2s fadeIn;
  min-height: 400px;
  max-height: 800px;
  height: calc(100vh - 151px);

  img.background {
    width: 100%;
    height: calc(100vh - 151px);
    min-height: 400px;
    max-height: 800px;
    object-fit: cover;
  }

  .content {
    text-align: center;
    position: absolute;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: $size-big;
    margin: $size-big;

    img {
      border-radius: 8px;
    }

    > div {

      h2,
      p {
        font-weight: 700;
        color: $color-blue;
      }

      p {
        margin-bottom: 0;
      }
    }
  }

  /* For IE */
  .content::-ms-expand {
    top: 16px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .not-found-page,
  .error-page {
    /* For IE */
    .content {
      top: 16px;
      transform: translate(-50%, 0);
    }
  }
}

@media only screen and (max-width: 992px) {
  .not-found-page,
  .error-page {
    height: calc(100vh - 92px);

    img.background {
      height: calc(100vh - 92px);
    }
  }
}

@media only screen and (max-width: 576px) {
  .not-found-page,
  .error-page {
    height: calc(100vh - 77px);

    img.background {
      height: calc(100vh - 77px);
    }

    .content img {
      height: 80px;
      width: 225px;
    }
  }
}
