@import 'variables';
@import 'color';

header {
  border-bottom: 1px solid $color-gray-border;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 99;
  background-color: $color-primary;
  min-height: 150px;
}

/* First row with logo and social icons */
.logo-social-row {
  padding: $size-big 0;

  .logo {
    max-width: 265px;
  }

  /* Mobile right*/
  .search-menu {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    position: unset;
  }
}

/* Second row with navigation menu, search and login*/
.login .primary-button {
  font-weight: 700;
}

.navigation-login-row {
  .login-search-social {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    text-transform: uppercase;
  }

  .search {
    margin-right: $size;
  }
}

/* Search */
.header-search.desktop form,
.header-search.tablet.laptop form,
.header-search.mobile form {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  position: relative;

  input {
    border: 0;
    background-color: transparent;
    line-height: 1;

    &:focus {
      outline: none;
    }
  }

  ::placeholder {
    color: $color-secondary;
    opacity: 1; /* Firefox */
  }

  .header-search-input {
    text-align: right;
    text-transform: uppercase;
    margin-right: $size-small;
    border: 2px solid $color-primary;
    padding: 2px 30px 2px 8px;
    border-radius: 45px;
    max-width: 120px;
    transition: all 0.7s ease-in-out;

    &:hover,
    &:focus {
      background-color: $color-gray-background-two;
      border-color: $color-gray-background-two;
      max-width: 160px;
    }
  }

  .header-submit {
    position: absolute;
    right: 14px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNDA3NzMgMTIuNjQ3NUMxMC4wMjYgMTIuNjQ3NSAxMi4xNDg1IDEwLjUyNSAxMi4xNDg1IDcuOTA2NzZDMTIuMTQ4NSA1LjI4ODUyIDEwLjAyNiAzLjE2NjAyIDcuNDA3NzMgMy4xNjYwMkM0Ljc4OTQ5IDMuMTY2MDIgMi42NjY5OSA1LjI4ODUyIDIuNjY2OTkgNy45MDY3NkMyLjY2Njk5IDEwLjUyNSA0Ljc4OTQ5IDEyLjY0NzUgNy40MDc3MyAxMi42NDc1WiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTMuMzMzNiAxMy44MzM2TDEwLjc1NTkgMTEuMjU1OSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: 0;
    border-radius: 4px;
    transition: all 0.3s ease-in-out;
  }
}

.header-search.mobile.tablet {
  margin-right: $size-double;

  button {
    padding: 0;
  }

  .header-form-search {
    display: none;
    background-color: $color-primary;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 999;
    border-bottom: 1px solid #D8D8D8;
  }

  form {
    .header-search-input {
      border: 2px solid $color-gray-border;
      padding: 8px 30px 8px 16px;
      margin: $size;
      width: 100%;
      max-width: 100%;
      text-align: left;

      &:hover,
      &:focus {
        background-color: $color-gray-background-two;
        max-width: 100%;
      }
    }

    .header-submit {
      position: absolute;
      right: 30px;
    }
  }
}

/* Social icons + language */
.social-icons-language {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  > *:not(:last-child) {
    margin-right: $size;
  }

  .social-icon {
    height: 16px;
    opacity: 0.3;
    display: flex;
    flex-flow: row nowrap;

    &:hover {
      opacity: 1;
    }
  }
}

.social-icons-language .divider {
  margin-right: $size;
  color: $color-gray-text-heading;
}

/* Language dropdown */
.language {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  .language-dropdown {
    border: 0;
    font-size: 14px;
    color: $color-gray-text-heading;
    outline: none;
    transition: 0.3s ease-in-out;
    min-width: 20px;

    div {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      min-width: 40px;
      text-align: center;
      border: 1px solid $color-gray-border;
      z-index: 99;
      background-color: white;
      transition: all 0.3s ease-in-out;
      top: 120%;
      left: 9px;
      transform: translate(-50%, 0);
      border-radius: 4px;
      overflow: hidden;
      padding: 3px;

      a {
        display: block;
        color: $color-gray-text-heading;
        line-height: $size-big;
        border-radius: 3px;
        transition: all 0.3s ease-in-out;

        &:hover {
          color: black;
          background-color: $color-gray-border;
        }
      }

      a:first-of-type {
        margin-bottom: 3px;
      }
    }
  }

  &:focus {
    border: 0;
  }

  &:hover .language-dropdown div {
    visibility: visible;
    opacity: 1;
  }

  &:hover .language-dropdown > span {
    color: $color-secondary;
    font-weight: 700;
  }

  &:hover .lang-toggle-arrow span {
    background-color: $color-secondary;
  }
}

/* For IE */
.language-dropdown::-ms-expand {
  display: none;
}

@media screen and (min-width: 0 \0
) {
  select {
    background: none \9
  ;
    padding: 5px \9
  ;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header-search.desktop form .header-search-input,
  .header-search.mobile form .header-search-input,
  .header-search.tablet.laptop form .header-search-input {
    width: 160px;
  }

  .header-search.desktop form .header-submit,
  .header-search.mobile form .header-submit,
  .header-search.tablet.laptop form .header-submit {
    top: 5px;
  }
}

@media only screen and (max-width: 992px) {
  header {
    min-height: 89px;
  }

  .logo-social-row .logo {
    max-width: 200px;
  }

  /* Second row mobile */
  .navigation-login-row {
    background-color: #fff;
    display: none;
    position: fixed;
    z-index: 998;
    margin: 0;
    top: 89px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;

    > div {
      height: 100%;
      align-content: space-between;
    }

    .login-search-social {
      justify-content: space-between;
      padding-top: $size-extra-big;
      padding-bottom: $size-extra-big;
      border-top: 1px solid $color-gray-border;
    }

    .social-icons-language {
      justify-content: flex-end;

      > a {
        margin-right: $size;
      }
    }
  }
}

.wrap-menu .main-dialog {
  display: none;
}

@media only screen and (max-width: 1200px) {
  .social-icons-language {
    align-items: center;
  }

  .navigation-login-row {
    .login-search-social {
      display: none;
    }
  }
}

@media only screen and (max-width: 992px) {
  .wrap-menu .main-dialog {
    display: initial!important;
  }

  /* First row with logo and social icons */
  .logo-social-row {
    /* To be removed after beta test banner is removed from the site*/
    .logo {
      margin-left: $size;
    }
  }

  .navigation-login-row {
    .login-search-social {
      display: flex;

      .login {
        margin-right: $size;
      }
    }
  }

  /* Language dropdown */
  .language .language-dropdown {
    div {
      top: unset;
      bottom: 120%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .navigation-login-row .login-search-social {
    padding-top: $size-big;
    padding-bottom: $size-big;
  }
}

@media only screen and (max-width: 576px) {
  header {
    min-height: 73px;

    .logo-social-row {
      padding: $size 0;
    }

    .navigation-login-row {
      top: 73px;
    }
  }

  .header-search.mobile.tablet {
    margin-right: $size;
  }
}
