.teaser {
  background-size: cover;
  background-position: center;
  padding-top: $size-double;
  padding-bottom: $size-double;

  @media screen and (min-width: 768px) {
    padding-top: $size-extra-big;
    padding-bottom: $size-extra-big;
  }

  @media screen and (min-width: 1024px) {
    padding-top: $size-footer-margin;
    padding-bottom: $size-footer-margin;
  }

  &-content {
    margin-bottom: 2rem;

    @media screen and (min-width: 768px) {
      max-width: 370px;
    }

    @media screen and (min-width: 1024px) {
      max-width: 500px;
    }

    h1, h2, h3 {
      text-align: center;
    }

    &.black {
      h1, h2, p {
        color: $color-secondary;
      }
    }

    &.white {
      h1, h2, p {
        color: $color-primary;
      }
    }

    &.yellow {
      h1, h2, p {
        color: $color-tertiary;
      }
    }

    &.darkblue {
      h1, h2, p {
        color: $color-blue;
      }
    }

    &.orange {
      h1, h2, p {
        color: $color-red;
      }
    }
  }

  &-headline {
    font-weight: 700;
    margin-bottom: 1rem;
  }

  &-btn {
    border-radius: 25px;
    border: 2px solid;
    font-weight: 700;
    padding: 1px 15px;
    text-transform: uppercase;

    &__primary {
      background-color: $color-tertiary;
      border-color: $color-tertiary;
      color: $color-secondary;

      &:hover {
        background-color: transparent;
        border-color: $color-tertiary;
        color: $color-tertiary;
      }
    }

    &__secondary {
      background-color: $color-secondary;
      border-color: $color-secondary;
      color: $color-secondary;

      &:hover {
        background-color: transparent;
        border-color: $color-secondary;
        color: $color-secondary;
      }
    }

    &__negative {
      background-color: $color-primary;
      border-color: $color-primary;
      color: $color-secondary;

      &:hover {
        background-color: transparent;
        border-color: $color-secondary;
        color: $color-primary;
      }
    }

    &__2drv {
      background-color: $color-blue;
      border-color: $color-blue;
      color: $color-primary;

      &:hover {
        background-color: transparent;
        border-color: $color-blue;
        color: $color-blue;
      }
    }

    &__axxion {
      background-color: $color-red;
      border-color: $color-red;
      color: $color-primary;

      &:hover {
        background-color: transparent;
        border-color: $color-red;
        color: $color-red;
      }
    }
  }
}
