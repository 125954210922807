.rims {
  &-list {
    display: flex;
    list-style: none;
    gap: 2rem;
  }
}

.rim {
  h3 {
    text-align: center;
  }
}
