@import "variables";

.nav-toggle-arrow,
.accordion-arrow,
.filter-arrow,
.language .lang-toggle-arrow {
  width: 14.4px;
  min-width: 14.4px;
  height: 14.4px;
  position: relative;
  margin: 0 0 0 $size-half;
  cursor: pointer;
  transition: 0.2s ease-in-out;

  span {
    top: 6px;
    position: absolute;
    width: 9px;
    height: 1.5px;
    background-color: #000;
    display: inline-block;
    transition: 0.2s ease-in-out;

    &:first-of-type {
      left: 0;
      transform: rotate(45deg);
    }

    &:last-of-type {
      right: 0;
      transform: rotate(-45deg);
    }
  }

  &.arrow-active span {
    &:first-of-type {
      transform: rotate(-45deg);
    }

    &:last-of-type {
      transform: rotate(45deg);
    }
  }
}

.yellow-circle-arrow,
.blue-circle-arrow {
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-color: $color-tertiary;
  margin: 0 0 0 $size-half;
  position: relative;
  transition: 0.3s ease-in-out;

  img,
  .accordion-arrow {
    margin-left: 0;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
  }
}

.blue-circle-arrow {
  height: 24px;
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: $color-blue;
}

.language {
  &:hover .language-dropdown ~ .lang-toggle-arrow span {
    &:first-of-type {
      transform: rotate(-45deg);
    }

    &:last-of-type {
      transform: rotate(45deg);
    }
  }

  .lang-toggle-arrow span {
    background-color: $color-gray-text-heading;
  }
}

@media only screen and (max-width: 992px) {
  .nav-toggle-arrow {
    min-width: 20px;
    min-height: 24px;
    margin: 0 0 0 $size;

    span {
      top: 13px;
      width: 12px;
    }
  }

  .language {
    .lang-toggle-arrow span {
      &:first-of-type {
        left: 0;
        transform: rotate(-45deg);
      }

      &:last-of-type {
        right: 0;
        transform: rotate(45deg);
      }
    }


    &:hover .language-dropdown ~ .lang-toggle-arrow span {
      &:first-of-type {
        transform: rotate(45deg);
      }

      &:last-of-type {
        transform: rotate(-45deg);
      }
    }
  }
}

