@import "variables";
@import "color";

footer {
  background-color: $color-secondary;
  z-index: 99;

  p.heading {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 $size 0;
    color: #ffffff;
    text-transform: uppercase;
  }

  a {
    text-transform: lowercase;
  }

  p:not(.heading) {
    color: rgba(255, 255, 255, 0.5);
    margin: 0;
  }

  .first-row {
    padding: $size-extra-big 0;

    .logo {
      max-width: 265px;
    }
  }

  .second-row,
  .second-third-column {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    > div:not(:last-child) {
      margin-right: $size;
    }

    .first-column {
      max-width: 371px;

      p:not(.heading) {
        line-height: 1.65;
        margin-bottom: $size;
      }

      a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px;

        svg {
          margin-left: $size-half;
          stroke: $color-primary;
          transition: 0.3s ease-in-out;
        }

        &:hover svg {
          stroke: $color-primary;
        }
      }
    }

    .second-column {
      min-width: 210px;

      p:not(.heading):not(.phone):not(.email) {
        line-height: 1.65;
      }

      .phone,
      .email {
        font-size: 14px;
      }

      .phone {
        margin-bottom: $size-half;
      }

      .email {
        margin: 0 0 $size 0;
      }
    }

    .third-column {
      min-width: 110px;
    }

    .social-icons {
      display: flex;
      flex-flow: row wrap;

      a {
        opacity: 0.7;

        &:hover {
          opacity: 1;
        }
      }

      a:not(:last-child) {
        margin-right: $size-big;
      }
    }
  }

  .second-row {
    padding-bottom: $size-extra-big*2;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }

  .third-row {
    padding: $size-big 0;

    a {
      font-size: 14px;
      color: #ffffff;
      text-decoration: none;
      text-transform: uppercase;

      &:hover {
        color: $color-primary;
      }

      &:not(:last-child) {
        margin-right: 46px;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  footer .second-row {
    flex-flow: row wrap;
    padding-bottom: $size-extra-big;

    > div:not(:last-child) {
      margin-right: 0;
      margin-bottom: $size-extra-big;
    }

    .second-third-column {
      flex: 1 100%;
      justify-content: flex-start;
    }
  }
}

@media only screen and (max-width: 576px) {
  footer {
    .first-row,
    .third-row {
      padding-top: $size-double;
    }

    .first-row,
    .second-row,
    .third-row {
      padding-bottom: $size-double;
    }

    .second-row,
    .second-third-column {
      flex-flow: row wrap;

      > div {
        flex: 1 100%;
      }

      > div:not(:last-child) {
        margin-right: 0;
        margin-bottom: $size-double;
      }
    }

    .third-row nav {
      display: flex;
      flex-flow: column;

      a:not(:last-child) {
        padding-bottom: $size;
      }
    }


  }
}
