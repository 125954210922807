.textimage {
  padding-top: $size-extra-big;
  padding-bottom: $size-extra-big;

  @media screen and (min-width: 577px) {
    padding-top: $size-extra-big + $size-double;
    padding-bottom: $size-extra-big + $size-double;
  }

  &-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;

    @media screen and (min-width: 992px) {
      flex-flow: row nowrap;
      justify-content: space-between;
    }

    &__left {
      .textimage-image {
        margin-bottom: $size-extra-big;

        @media only screen and (min-width: 992px) {
          margin-right: $size-extra-big + $size-double;
          margin-bottom: 0;
        }
      }

      .textimage-text {
        margin-bottom: 0;
      }
    }

    &__right {
      flex-direction: column-reverse;

      @media only screen and (min-width: 992px) {
        flex-direction: row-reverse;
      }

      .textimage-image {
        margin-top: 0;
        margin-bottom: 0;
      }

      .textimage-text {
        margin-bottom: $size-extra-big;

        @media only screen and (min-width: 992px) {
          margin-right: $size-extra-big + $size-double;
          margin-bottom: 0;
        }
      }
    }
  }

  &-image {
    flex: 1 100%;
    max-width: 100%;

    @media only screen and (min-width: 992px) {
      flex: 1 50%;
      max-width: 50%;
    }
  }

  &-text {
    flex: 1 100%;
    max-width: 100%;

    @media only screen and (min-width: 992px) {
      flex: 1 50%;
      max-width: 50%;
    }

    h2 {
      text-align: center;
      font-weight: 700;

      span {
        display: block;
        font-weight: 400;
      }
    }

    h1:last-of-type,
    h2:last-of-type,
    h3:last-of-type,
    h4:last-of-type,
    h5:last-of-type,
    h6:last-of-type {
      margin-bottom: $size-double;
    }
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 4px;
  }

  h2 {
    text-align: center;
  }
}
