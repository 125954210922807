/* Brand colors */
$blue-color: #08315A;
$red-color: #FC5F20;

$main-brand-color: var(--main-brand-color);

.blueColor {
  --main-brand-color: #{$blue-color};
}

.redColor {
  --main-brand-color: #{$red-color};
}
