@import "../base/color";
@import "../base/variables";

.search-page {
  padding: 0 0 ($size-extra-big + $size-double) * 2 0;

  .search-featured-image .content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }

  .search-results {
    padding-top: ($size-extra-big + $size-double);

    h5 {
      margin-bottom: 40px;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    li {
      margin-bottom: 15px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 15px;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}

.empty-search {
  height: calc(100vh - 151px);
  min-height: 400px;
  max-height: 800px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.empty-search,
.search-featured-image {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  animation: 2s fadeIn;

  img.background {
    width: 100%;
    height: calc(100vh - 151px);
    min-height: 400px;
    max-height: 800px;
    object-fit: cover;
  }

  .content {
    text-align: center;
    position: absolute;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: $size-big;
    margin: $size-big;

    img {
      border-radius: 8px;
    }

    > div {

      h2,
      p {
        font-weight: 700;
        color: $color-blue;
      }

      p {
        margin-bottom: 0;
      }

      span {
        font-size: 18px;
      }
    }
  }

  /* For IE */
  .content::-ms-expand {
    top: 16px;
  }
}

.search-featured-image img.background {
  height: 200px;
  min-height: 200px;
}

.search-highlight {
  font-weight: bold;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .empty-search {
    /* For IE */
    .content {
      top: 16px;
      transform: translate(-50%, 0);
    }
  }
}

@media only screen and (max-width: 992px) {
  .search-page {
    padding: 0 0 ($size-extra-big + $size-double) 0;

    .search-results {
      padding-top: $size-extra-big;
    }
  }

  .empty-search {
    height: calc(100vh - 92px);

    img.background {
      height: calc(100vh - 92px);
    }
  }

  .search-featured-image img.background {
    height: 150px;
    min-height: 150px;
  }
}

@media only screen and (max-width: 576px) {
  .search-page .search-featured-image .content {
    padding: $size;

    img:first-of-type {
      width: 35px;
      height: 35px;
    }

    h2 {
      margin-right: $size;
    }

    img:last-of-type {
      width: 30px;
      height: 30px;
    }
  }

  .empty-search {
    height: calc(100vh - 77px);

    img.background {
      height: calc(100vh - 77px);
    }

    .content img {
      height: 80px;
      width: 225px;
    }
  }
}


