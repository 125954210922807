@import "../base/color";
@import "../base/variables";

.news-overview {
  padding: ($size-extra-big + $size-double) 0 ($size-extra-big + $size-double) * 2 0;

  .news-overview-wrapper {
    background-color: $color-gray-background-two;
  }
}

@media only screen and (max-width: 992px) {
  .news-overview {
    padding: $size-extra-big 0 ($size-extra-big + $size-double) 0;
  }
}
