.hintbox {
  padding-top: $size-extra-big;
  padding-bottom: $size-extra-big;

  @media screen and (min-width: 1024px) {
    padding-top: $size-extra-big + $size-double;
    padding-bottom: $size-extra-big + $size-double;
  }

  &:not(.wrap-full) {
    padding-left: $size-big;
    padding-right: $size-big;

    @media screen and (min-width: 1024px) {
      padding-left: $size-extra-big + $size-double;
      padding-right: $size-extra-big + $size-double;
    }
  }

  &.yellow {
    background-color: $color-tertiary;
  }

  &.black {
    background-color: $color-secondary;

    h2, p {
      color: white;
    }
  }

  &-inner {
    display: flex;
    flex-direction: column-reverse;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  &-content {
    @media screen and (min-width: 1024px) {
      max-width: 50%;
      margin-right: 50px;
    }
  }

  &-icon {
    display: block;
    height: 118px;
    margin-bottom: $size-double;
    width: 129px;
  }

  &-headline {
    font-weight: 700;
    margin-bottom: $size-double;
  }

  &-cta {
    margin-top: $size;
  }
}

