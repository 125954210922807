@import "../base/color";
@import "../base/variables";
@import "../base/changing-colors";

.brand-page {
  /* Heading texts */
  .heading {
    margin-top: $size-extra-big*2;
    margin-bottom: $size-extra-big*2;
    text-align: center;

    * {
      margin-bottom: 0;
    }
  }

  /* The two images */
  .two-images {
    max-width: 100%;
    margin: 0 !important;

    .two-images-content {
      padding: 0;
      position: relative;

      > div {
        position: absolute;
        padding: $size-extra-big;
        bottom: 0;
        left: 0;

        h1 {
          font-weight: 700;
          max-width: 470px;
        }

        p {
          font-size: 14px;
          line-height: 1.65;
          max-width: 380px;
        }

        *:last-child {
          margin-bottom: 0;
        }
      }
    }

    img {
      height: 800px;
      object-fit: cover;
      width: 100%;
    }
  }

  /* Catalog */
  .catalog {
    background-color: $color-gray-background-two;

    .wrap {
      padding-top: $size-extra-big*2;
      padding-bottom: $size-extra-big*2
    }

    .row > div {
      text-align: center;
    }

    .img-catalog {
      box-shadow: -1px 46px 42px 3px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
    }

    p {
      color: $color-secondary-opacity;
    }
  }

  /* Image with logo and button */
  .image-logo-button {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;

    picture {
      width: 100%;
    }

    picture img {
      width: 100%;
      object-fit: cover;
      height: calc(100vh - 151px);
      max-height: 800px;
      min-height: 450px;
    }

    .content {
      position: absolute;
      text-align: center;
      padding-top: $size-big;
      padding-bottom: $size-big;

      img {
        margin-bottom: $size-big;
        max-width: 400px;
      }

      h4 {
        margin-bottom: $size-big;
      }

      a {
        margin: 0 auto 16px auto;
      }
    }
  }
}

/* Page Main Color Options */
.brand-page.blueColor {
  /* Heading texts */
  .heading h1 strong {
    color: $color-blue;
  }

  /* Catalog */
  .catalog h1 {
    color: $color-blue;
  }
}

.brand-page.redColor {
  /* Heading texts */
  .heading h1 strong {
    color: $color-red;
  }

  /* Catalog */
  .catalog h1 {
    color: $color-red;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .brand-page .image-logo-button {
    display: block;
    text-align: center;

    .content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

@media only screen and (max-width: 992px) {
  /* Heading texts */
  .brand-page {
    .heading {
      margin-top: $size-extra-big;
      margin-bottom: $size-extra-big;
    }

    /* The two images */
    .two-images {
      .two-images-content > div {
        padding: $size-double;
      }

      img {
        height: 700px;
      }
    }

    /* Catalog */
    .catalog {
      .wrap {
        padding-top: $size-extra-big;
        padding-bottom: $size-extra-big;
      }

      .row > div:first-child {
        margin-bottom: $size-extra-big;

        .img-catalog {
          width: 276px;
          height: 366px;
        }
      }
    }

    /* Image with logo and button */
    .image-logo-button {
      picture img {
        height: calc(100vh - 89px);
        max-height: 580px;
      }

      .content img {
        max-width: 320px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  /* Heading texts */
  .brand-page {
    /* Image with logo and button */
    .image-logo-button .content img {
      max-width: 280px;
    }
  }
}

@media only screen and (max-width: 576px) {
  /* Heading texts */
  .brand-page {
    /* The two images */
    .two-images {
      .two-images-content > div {
        padding: $size;
      }

      img {
        height: 590px;
      }
    }

    /* Image with logo and button */
    .image-logo-button {
      picture img {
        height: calc(100vh - 72px);
      }

      .content img {
        max-width: 240px;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  /* Heading texts */
  .brand-page .catalog .catalog-button span {
    max-width: 60%;
  }
}
