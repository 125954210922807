@import "color";

a {
  color: $color-secondary;
  text-decoration: none;
  word-break: break-word;
  transition: 0.3s ease-in-out;

  &:focus,
  &:active,
  &:visited {
    color: $color-secondary;
  }

  &:hover {
    color: $color-secondary;
    font-weight: 900;
  }
}

a.reverse p.nav-title:hover {
  color: $color-secondary;
  font-weight: bold;
}

a.reverse {
  color: $color-secondary;

  &:focus,
  &:active,
  &:visited {
    color: $color-secondary;
  }

  &:hover {
    color: $color-secondary;
  }
}

a.reverse-white,
a.reverse-white-opacity {
  color: $color-primary;

  &:focus,
  &:active,
  &:visited {
    color: $color-primary;
  }

  &:hover {
    color: $color-primary;
  }
}

a.reverse-white-opacity {
  color: $color-primary-opacity;

  &:focus,
  &:active,
  &:visited {
    color: $color-primary-opacity;
  }
}

a.no-hover {
  color: $color-secondary;

  &:focus,
  &:active,
  &:visited {
    color: $color-secondary;
  }
}


.box-links {
  display: flex;
  flex-flow: column;
  transition: 0.3s ease-in-out;

  a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background-color: $color-primary;
    padding: $size;
    font-weight: 700;
    border-radius: 8px;
    overflow: hidden;

    &:not(:last-child) {
      margin-bottom: $size-half;
    }

    .yellow-circle-arrow {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in-out;

      svg {
        stroke: $color-secondary;
        transition: 0.3s ease-in-out;
      }
    }

    &:hover,
    a:hover {
      background-color: $color-gray-border;

      //.yellow-circle-arrow {
      //  background-color: $color-secondary;
      //
      //  svg {
      //    stroke: $color-tertiary;
      //  }
      //}
    }
  }
}
