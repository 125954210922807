/* Brand fonts */

@font-face {
  font-family: 'NeueMontreal';
  src: url('/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.eot') format('eot'),
  url('/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.otf') format('opentype'),
  url('/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.ttf') format('ttf'),
  url('/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.woff') format('woff'),
  url('/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DinNext';
  src: url('/assets/fonts/DinNext/DinNextLTPro-MediumCond.eot') format('eot'),
  url('/assets/fonts/DinNext/DinNextLTPro-MediumCond.otf') format('opentype'),
  url('/assets/fonts/DinNext/DinNextLTPro-MediumCond.ttf') format('ttf'),
  url('/assets/fonts/DinNext/DinNextLTPro-MediumCond.woff') format('woff'),
  url('/assets/fonts/DinNext/DinNextLTPro-MediumCond.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

$dinNext: 'DinNext';
$neueMontreal: 'NeueMontreal';

$main-brand-font: var(--main-brand-font);
.dinNext {
  --main-brand-font: #{$dinNext};
}

.neueMontreal {
  --main-brand-font: #{$neueMontreal};
}
