.brandimages {
  overflow: hidden;
  position: relative;
  transition: all 0.7s ease-in;
  opacity: 1;
  animation: 2s fadeIn;
  max-width: 2560px;

  @media screen and (min-width: 1024px) {
    height: calc(100vh - 293px);
    max-height: 728px;
    min-height: 400px;
    overflow: hidden;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      left: -6%;
      position: absolute;
      width: 112%;
    }

    @media screen and (min-width: 1440px) {
      width: 110%;
      left: -5%;
    }
  }

  &-brand {
    background-color: #000;
    border-bottom: 3px solid white;
    height: calc(24vh);
    overflow: hidden;
    position: relative;
    transition: 0.8s ease-out;
    width: 100%;

    @media screen and (min-width: 1024px) {
      border-right: 3px solid white;
      flex: 33.3333%;
      height: 100%;
      transform: skew(-13deg);
      width: 33.33333%;
    }

    &.active {
      @media screen and (max-width: 1023px) {
        height: 34vh;
      }

      @media screen and (min-width: 1024px) {
        flex: 45% !important;
      }

      .brandcontent {
        opacity: 1;
        height: 255px;
        top: 30px;

        @media screen and (min-width: 1024px) {
          height: 175px;
          top: auto;
          bottom: 50px;
        }

        &-button {
          opacity: 1;

          &--initial {
            opacity: 0;
          }

          &--top {
            bottom: 50px;
            opacity: 1;

            @media screen and (min-width: 1024px) {
              bottom: 0;
            }
          }

          &--bottom {
            bottom: 0;

            @media screen and (min-width: 1024px) {
              bottom: 44px;
            }
          }
        }
      }

      img {
        opacity: 0.7;

        @media screen and (max-width: 1023px) {
          top: -35%;
          height: 150%;
          width: 150%;
        }
      }
    }

    &.small {
      @media screen and (max-width: 1023px) {
        height: 20vh;
      }

      .brandcontent {
        opacity: 0;

        @media screen and (max-width: 1023px) {
          top: 50px;
        }

        &-button--initial {
          opacity: 1;
        }
      }

      img {
        opacity: 1;
      }
    }

    &:nth-child(1) {
      @media screen and (min-width: 1024px) {
        width: 40%;
      }

      &.active {
        img {
          object-position: 35px 63%;
          width: 215%;
          left: -50%;

          @media screen and (min-width: 1024px) {
            object-position: 35px 73%;
          }
        }
      }

      .brandcontent {
        @media screen and (min-width: 1024px) {
          left: 33%;
          width: 65%;
        }

        @media screen and (min-width: 1280px) {
          //min-height: 175px;
        }

        &-button {
          &--initial {
            @media screen and (min-width: 1024px) {
              left: 33%;
            }
          }
        }
      }
    }

    &:nth-child(2) {
      .brandcontent,
      .brandcontent-button--initial {
        @media screen and (min-width: 1024px) {
          left: 7%;
        }
      }

      img {
        @media screen and (max-width: 1023px) {
          object-position: 113px 65%;
          width: 180%;
        }
      }

      &.active {
        img {
          object-position: 192px 60%;
          width: 250%;
          left: -50%;

          @media screen and (min-width: 1024px) {
            object-position: 120px 73%;
            width: 200%;
          }
        }
      }
    }

    &:nth-child(3) {
      @media screen and (min-width: 1024px) {
        border-right: none;
        width: 33%;
      }

      .brandcontent,
      .brandcontent-button--initial {
        @media screen and (min-width: 1024px) {
          left: 7%;
        }
      }
    }

    .primary-button {
      font-size: 1rem;
      min-height: 32px;
      max-height: 32px;
      padding: 3px 20px;
      width: 290px;
    }

    & > .primary-button {
      top: 30px;
      left: 20px;
      position: absolute;
      z-index: 0;

      @media screen and (min-width: 1024px) {
        top: auto;
        bottom: 50px;
        transform: skew(13deg);
        left: auto;
      }
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    object-position: 25% 70%;
    position: relative;
    top: 0;
    left: -30%;
    transition: all 0.8s ease-out;
    width: 150%;

    @media screen and (min-width: 1024px) {
      height: calc(100vh - 293px);
      max-height: 728px;
      min-height: 400px;
      left: -20%;
      transform: skew(13deg);
      width: 160%;
    }
  }
}

.brandcontent {
  bottom: 80px;
  left: 20px;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transition: all 0.8s ease-out;
  top: 50px;
  width: 80%;
  z-index: 1;

  @media screen and (min-width: 1024px) {
    bottom: 50px;
    transform: skew(13deg);
    top: auto;
    width: 70%;
  }

  @media screen and (min-width: 1280px) {
    width: 80%;
  }

  &-button {
    bottom: 0;
    position: absolute;
    transition: all 0.8s ease-out;
    white-space: nowrap;

    &:hover {
      font-weight: 700;
    }

    @media screen and (min-width: 1024px) {
      left: 0;
    }

    &--top {
      opacity: 0;
    }
  }

  h2, p {
    color: white;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
  }

  h2 {
    text-transform: uppercase;
  }
}
