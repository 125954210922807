.featured-image.certificates {
  img {
    object-position: 50% 65%;
  }

  .featured-image-heading span {
    max-width: 650px;
    margin: 0 auto;
  }
}

.certificates-content {
  position: relative;
  min-height: 100vh;
  animation: 0.7s fadeIn;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
