.jobs {
  background-color: $color-gray-background-two;
  margin-top: 0;
  margin-bottom: 0;

  &-field {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: $size-extra-big + $size-double;
    padding-bottom: $size-extra-big + $size-double;

    > div {
      flex: 1 50%;

      &:first-child {
        margin-right: $size-extra-big + $size-double;
      }
    }

    h3 {
      margin-bottom: $size-double;
    }

    a {
      text-transform: uppercase;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
    }

    .job-candidature {
      h3 {
        color: $color-gray-text-heading
      }

      .candidature {
        padding-bottom: $size-extra-big + $size;
        margin-bottom: $size-extra-big + $size;
        border-bottom: 1px solid $color-gray-border;

        a {
          margin-top: $size-double;
          font-weight: 600;
          justify-content: flex-start;


          svg {
            margin-left: $size;
            stroke: $color-secondary;
            transition: 0.4s ease-in-out;
          }
        }
      }

      .contact-content {
        display: flex;
        flex-flow: row wrap;

        > div:first-child {
          margin-right: $size-double;
          min-width: 200px;
        }

        p, a {
          margin: 0;
          line-height: 1.8;
        }
      }

      a,
      .contact p {
        font-size: 14px;
      }
    }
  }
}

@media only screen and (max-width: 1440px) {

}

@media only screen and (max-width: 992px) {
  .jobs {
    &-field {
      flex-flow: row wrap;

      h3 {
        margin-bottom: $size-big;
      }

      > div {
        flex: 1 100%;

        &:first-child {
          margin: 0 0 $size-extra-big 0;
        }
      }

      .job-candidature .candidature {
        padding-bottom: $size-extra-big;
        margin-bottom: $size-extra-big;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .jobs {
    &-field {
      padding-top: $size-extra-big;
      padding-bottom: $size-extra-big;

      .job-candidature {
        .candidature {
          padding-bottom: $size-big;
          margin-bottom: $size-big;

          a {
            margin-top: $size-big;
          }
        }

        .contact-content {
          flex-flow: column;

          > div:first-child {
            margin: 0 0 $size 0;
          }
        }
      }
    }
  }
}
