.accordion {
  border-top: 1px solid $color-grey-border-2;

  > div {
    transition: 0.5s ease-in-out;
  }

  > div:hover {
    background-color: $color-gray-background-two;

    .yellow-circle-arrow {
      background-color: $color-secondary;

      span span {
        background-color: $color-tertiary;
      }
    }
  }

  &-wrapper {
    border-bottom: 1px solid $color-grey-border-2;
  }

  &-heading {
    padding: $size;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &.is-active {
      border-bottom: none;
      background-color: $color-gray-background-two;
    }
  }

  &-content {
    padding: $size;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 992px) {
      flex-direction: row;
    }

    &.is-active {
      background-color: $color-gray-background-two;
    }

    &-text {
      flex: 0 0 70%;
    }

    &-image {
      flex: 0 0 30%;
      max-width: 400px;

      @media only screen and (min-width: 992px) {
        padding-left: $size-double;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 300px;

        &:first-child {
          margin-bottom: $size-half;
        }
      }
    }
  }
}
