@import "../base/color";
@import "../base/variables";

/* Featured image with heading and text */
/* Addition of the code from the company file */
.featured-image.news {
  .featured-image-text {
    h1, h6 {
      max-width: 680px;
    }

    h6 {
      margin-bottom: $size;
    }
  }
}

.news-sidebar {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;

  .news-content {
    flex: 1 75%;
    margin-right: $size-extra-big *3;
  }

  .sidebar {
    flex: 1 25%;
    max-width: 25%;
    min-width: 300px;
  }
}

/*** News content ***/
.news-content {
  .news-table,
  .textimage,
  .rim-field,
  .news-accordion,
  .news-tabs,
  .news-button {
    margin: $size-extra-big + $size-double auto;
  }

  .wrap {
    margin-right: 0;
    margin-left: 0;
  }

  /* Rim field */
  .rim-field {
    .rim-image img {
      max-height: 900px;
      width: 100%;
      margin-bottom: $size-big;
      border-radius: 8px;
    }

    .rim-bar {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      background-color: $color-gray-light;
      padding: $size-small $size;
      border-radius: 8px;

      > div {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        flex-grow: 2;
        margin-right: $size;

        img {
          margin-right: $size;
        }
      }

      a {
        font-weight: 700;
      }
    }
  }

  /* News tabs */
  .news-tabs {
    .tabs-heading {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: $size-big;
      border-bottom: 1px solid #D8D8D8;
      transition: 0.3s ease-in-out;

      button {
        margin-bottom: $size-big;
        min-width: 135px;
        border: 2px solid $color-primary;
        transition: 0.3s ease-in-out;

        &.tab-color {
          color: $color-secondary;
          background-color: $color-tertiary;
          border-radius: 45px;
          border: 2px solid $color-tertiary;
        }

        &:hover {
          border: 2px solid $color-tertiary;
          color: $color-secondary;
        }
      }

      button:not(:last-child) {
        margin-right: $size;
      }
    }

    .tabs-text {
      display: none;
      min-height: 100px;

      &.is-active {
        transition: all 1s ease;
        display: block;

        > div {
          transition: all 1s ease;
          animation: fadeEffect 0.6s ease;
          width: 100%;
        }
      }

      @keyframes fadeEffect {
        from {
          opacity: 0;
          margin-left: 30px;
        }
        to {
          opacity: 1;
          margin-left: 0;
        }
      }
    }
  }
}

/*** Sidebar ***/
.sidebar {
  position: relative;
  margin-top: $size-extra-big + $size-double;

  /* Text color */
  p, a {
    color: $color-secondary-opacity;
    margin-bottom: 0;
  }

  /* Headings */
  p.heading {
    margin-bottom: $size;
    color: $color-secondary;
  }

  /* Social icons */
  .social .social-icons {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    a {
      margin-right: $size-big;
    }

    img {
      opacity: 0.3;
      transition: 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }

    input {
      height: 0;
      border: 0;
      padding: 0;
      outline: none;
    }

    /* Copy url button */
    button {
      padding: 0;
      position: relative;

      .tooltipText {
        visibility: hidden;
        width: 80px;
        background-color: #A4A4A4;
        color: #fff;
        text-align: center;
        border-radius: 3px;
        padding: 3px;
        position: absolute;
        z-index: 1;
        bottom: 130%;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 10px;
      }

      .tooltipText::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #A4A4A4 transparent transparent transparent;
      }

      &:hover .tooltipText {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  /* Contacts */
  .contact .email {
    margin-bottom: $size-big;

    a {
      text-transform: uppercase;
    }
  }

  /* Banner */
  .banner {
    img {
      max-width: 100%;
      border-radius: 4px;
    }
  }

  .social-icons,
  .contact,
  .banner {
    margin-bottom: $size-double
  }

  /* last articles */
  .last-articles {
    .box-links {
      margin-bottom: 24px;

      a {
        background-color: $color-gray-light;
        padding: 0;
        width: 100%;
        min-height: 66px;

        &:hover {
          background-color: #D8D8D8;
        }
      }
    }

    .article-text, span {
      margin: $size-small $size $size-small 0;
    }

    .article-text {
      flex-grow: 2;
      margin-left: $size;

      .title {
        font-size: 14px;
      }

      p {
        color: $color-secondary;
        font-weight: 400;
        white-space: nowrap;
        width: 160px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .article-img {
      background-color: $color-secondary-opacity;
      width: 66px;
      min-width: 66px;
      display: flex;
      align-items: stretch;

      img {
        width: 100%;
        height: 66px;
        object-fit: cover;
      }
    }
  }
}



/* Related articles */
.related-articles {
  flex: 1 100%;
  border-top: 1px solid $color-gray-border;
  padding: ($size-extra-big + $size-double) 0 ($size-extra-big + $size-double)*2 0;

  h1 {
    text-align: center;
    margin: 0 0 $size-extra-big 0;
  }

  > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;

    a {
      flex: 1 33%;
      min-width: 25%;

      &:not(:last-child) {
        margin-right: $size;
      }

      > div {
        padding: $size-double $size 0 $size;
      }
    }
  }

  img {
    width: 100%;
    height: 365px;
    object-fit: cover;
    border-radius: 6px;
  }
}

@media only screen and (max-width: 1300px) {
  .news-sidebar {
    /* last articles */
    .sidebar .last-articles {
      .article-text, span {
        margin: $size-small;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .news-sidebar {
    .news-content {
      margin-right: $size-extra-big;
    }
  }
}

@media only screen and (max-width: 992px) {
  /*** News content ***/
  .news-content {
    .news-table,
    .news-text-image,
    .rim-field,
    .news-accordion,
    .news-tabs,
    .news-button {
      margin: $size-extra-big auto;
    }
    /* Text and image */
    .news-text-image {
      flex-flow: row wrap;

      .text,
      .image {
        flex: 1 100%;
      }

      > div:first-child {
        margin: 0 0 $size-extra-big 0;
      }
    }
  }

  /*** Sidebar ***/
  .news-sidebar {
    display: flex;
    flex-flow: row wrap;

    .news-content {
      flex: 1 100%;
      margin: 0;
    }

    .sidebar {
      flex: 1 100%;
      max-width: 100%;
      margin: 0 0 $size-extra-big 0;
      display: none; // TODO
    }
  }

  /* Related articles */
  .related-articles {
    padding: $size-extra-big 0 $size-extra-big + $size-double 0;

    > div {
      flex-flow: row wrap;

      a {
        flex: 1 100%;
        max-width: 60%;
        margin: 0 auto;


        &:not(:last-child) {
          margin: 0 auto $size auto;
        }

        > div {
          padding: $size 0 0 0;
        }
      }
    }

    img {
      height: 256px;
    }
  }
}

@media only screen and (max-width: 768px) {
  /*** News content ***/
  .news-content {
    /* Rim field */
    .rim-field .rim-bar {
      flex-flow: row wrap;
      padding: $size;

      > div {
        margin: 0 auto $size auto;
        justify-content: center;
      }

      a {
        flex: 1 100%;
        max-width: 100%;
      }
    }

    /* News tabs */
    .news-tabs .tabs-heading button {
      min-width: 97px;
    }
  }
}

@media only screen and (max-width: 576px) {
  /*** News content ***/
  .news-content {
    .news-table,
    .news-text-image,
    .rim-field,
    .news-accordion,
    .news-tabs,
    .sidebar,
    .news-button {
      margin: $size-double auto;
    }

    .news-text-image .image img {
      max-height: 350px;
    }

    /* News tabs */
    .news-tabs .tabs-heading {
      button {
        margin-bottom: $size;
      }

      button:not(:last-child) {
        margin-right: $size-half;
      }
    }
  }

  /* Related articles */
  .related-articles {
    padding-top: $size-double;

    > div {

      a {
        max-width: 100%;
      }
    }

    img {
      height: 256px;
    }
  }
}


