.element {
  margin-top: $size-extra-big + $size-double;
  margin-bottom: $size-extra-big + $size-double;

  @media only screen and (max-width: 576px) {
    margin-top: $size-double;
    margin-bottom: $size-double;
  }

  @media only screen and (max-width: 992px) {
    margin-top: $size-extra-big;
    margin-bottom: $size-extra-big;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.colored-odd:nth-of-type(odd) {
  background-color: $color-gray-background-two;
}

.colored-even:nth-of-type(even) {
  background-color: $color-gray-background-two;
}
