@import "../base/color";
@import "../base/variables";

.featured-image.rimsOverview {
  img {
    object-position: 50% 65%;
  }
}

/* Heading texts */
.heading.wrap {
    margin-top: $size-extra-big*2;
    margin-bottom: $size-extra-big*2;
    text-align: center;
}

#rimsOverview-widget {
  height: 100%;
}
