/* Base
--------------------------------------------- */
.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

/* Brand fonts */
@font-face {
  font-family: 'NeueMontreal';
  src: url("/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.eot") format("eot"), url("/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.otf") format("opentype"), url("/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.ttf") format("ttf"), url("/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.woff") format("woff"), url("/assets/fonts/NeueMontreal/NeueMontreal-BoldItalic.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'DinNext';
  src: url("/assets/fonts/DinNext/DinNextLTPro-MediumCond.eot") format("eot"), url("/assets/fonts/DinNext/DinNextLTPro-MediumCond.otf") format("opentype"), url("/assets/fonts/DinNext/DinNextLTPro-MediumCond.ttf") format("ttf"), url("/assets/fonts/DinNext/DinNextLTPro-MediumCond.woff") format("woff"), url("/assets/fonts/DinNext/DinNextLTPro-MediumCond.woff2") format("woff2");
  font-weight: normal;
  font-style: normal; }

.dinNext {
  --main-brand-font: DinNext; }

.neueMontreal {
  --main-brand-font: NeueMontreal; }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-Regular.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-SemiBold.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/OpenSans-Bold.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/Roboto-Regular.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/Roboto-Bold.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/RobotoCondensed-Regular.woff2) format("woff2");
  font-display: swap; }

@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  font-stretch: 100%;
  src: url(/assets/fonts/OpenSans/RobotoCondensed-Bold.woff2) format("woff2");
  font-display: swap; }

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 15px;
  line-height: 0;
  margin: 0; }

@font-face {
  font-family: 'Commando';
  src: url("/assets/fonts/commando/Commando.eot") format("eot"), url("/assets/fonts/commando/Commando.ttf") format("ttf"), url("/assets/fonts/commando/Commando.woff") format("woff"), url("/assets/fonts/commando/Commando.woff2") format("woff2");
  font-weight: bold;
  font-style: normal; }

.featured-image-heading,
.carousel-slide .slide-heading,
.featured-image-news-heading {
  font-family: 'Commando', 'Roboto Condensed', sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 6px 0;
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1.3;
  word-break: break-word; }

.brand-page h1,
.brand-page h2,
.brand-page h3,
.brand-page h4,
.brand-page h5 {
  font-family: var(--main-brand-font), "Roboto Condensed", sans-serif !important; }

input,
select,
button {
  font-family: 'Open Sans', sans-serif !important;
  word-break: break-word; }

p {
  margin: 0 0 16px 0;
  line-height: 1.5; }

p, span {
  word-break: break-word; }

div {
  line-height: 1.3; }

ul li,
ol li {
  font-family: 'Open Sans', sans-serif;
  line-height: 1.3;
  font-size: 15px; }
  ul li p,
  ol li p {
    margin: 0; }

ul li::marker {
  color: #000000; }

span,
a,
table {
  line-height: 1.3; }

h1,
h1 * {
  font-size: 32px; }

.dinNext h1,
.dinNext h1 * {
  font-size: 34px; }

.neueMontreal h1,
.neueMontreal h1 * {
  font-size: 28px; }

h1.featured-image-heading,
h1.featured-image-heading * {
  font-size: 86px; }

h1.slide-heading,
h1.slide-heading * {
  font-size: 78px; }

h1.banner-heading,
h1.banner-heading * {
  font-size: 36px;
  line-height: 1.1; }

h2,
h2 * {
  font-size: 28px; }

.dinNext h2,
.dinNext h2 * {
  font-size: 30px; }

.neueMontreal h2,
.neueMontreal h2 * {
  font-size: 23px; }

h2.slide-heading,
h2.slide-heading *,
h2.error-heading {
  font-size: 53px; }

h3,
h3 *,
ul.secondary .nav-title,
ul.secondary .nav-title * {
  font-size: 24px; }

.dinNext h3,
.dinNext h3 * {
  font-size: 26px; }

.neueMontreal h3,
.neueMontreal h3 * {
  font-size: 19px; }

h3.slide-heading,
h3.slide-heading *,
h3.error-heading {
  font-size: 32px; }

h4,
h4 * {
  font-size: 20px; }

.dinNext h4,
.dinNext h4 * {
  font-size: 24px; }

.neueMontreal h4,
.neueMontreal h4 * {
  font-size: 18px; }

h5,
h5 * {
  font-size: 17px; }

h6,
h6 * {
  font-size: 14px; }

img {
  margin-bottom: -4px; }

@media only screen and (max-width: 1100px) {
  h1.slide-heading,
  h1.slide-heading * {
    font-size: 50px; }
  h1.banner-heading,
  h1.banner-heading * {
    font-size: 32px; }
  h2.slide-heading,
  h2.slide-heading *,
  h2.error-heading {
    font-size: 40px; }
  h3.slide-heading,
  h3.slide-heading *,
  h3.error-heading {
    font-size: 28px; } }

@media only screen and (max-width: 992px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 6px 0;
    font-weight: 400;
    text-transform: uppercase; }
  h1,
  h1 * {
    font-size: 28px; }
  h1.featured-image-heading,
  h1.featured-image-heading * {
    font-size: 70px; }
  h2,
  h2 *,
  ul.primary .nav-title,
  ul.primary .nav-title * {
    font-size: 24px; }
  h3,
  h3 * {
    font-size: 20px; }
  h4,
  h4 * {
    font-size: 18px; }
  h5,
  h5 * {
    font-size: 17px; }
  h6,
  h6 *,
  ul.secondary .nav-title,
  ul.secondary .nav-title * {
    font-size: 14px; } }

@media only screen and (max-width: 768px) {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 6px 0;
    font-weight: 400;
    text-transform: uppercase; }
  h1,
  h1 * {
    font-size: 24px; }
  h1.featured-image-heading,
  h1.featured-image-heading * {
    font-size: 60px; }
  h2,
  h2 *,
  ul.primary .nav-title,
  ul.primary .nav-title * {
    font-size: 22px; }
  h3,
  h3 *,
  ul.secondary .nav-title,
  ul.secondary .nav-title * {
    font-size: 20px; }
  h4,
  h4 * {
    font-size: 18px; }
  h5,
  h5 * {
    font-size: 17px; }
  h6,
  h6 *,
  ul.secondary .nav-title,
  ul.secondary .nav-title * {
    font-size: 14px; } }

@media only screen and (max-width: 576px) {
  h1.slide-heading,
  h1.slide-heading * {
    font-size: 32px; }
  h1.featured-image-heading,
  h1.featured-image-heading * {
    font-size: 50px; }
  h1.banner-heading,
  h1.banner-heading * {
    font-size: 22px; }
  h2.slide-heading,
  h2.slide-heading *,
  h2.error-heading {
    font-size: 25px; }
  h3.slide-heading,
  h3.slide-heading *,
  h3.error-heading {
    font-size: 20px; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

main {
  max-width: 2560px;
  margin: 0 auto; }

.wrap,
.wrap-menu {
  max-width: 1360px;
  margin: 0 auto; }

.wrap-menu {
  margin: 0 auto 24px auto; }

.wrap-full {
  max-width: 100%;
  margin-right: 40px;
  margin-left: 40px; }

.wrap-small {
  max-width: 700px;
  margin: 0 auto; }

.wrap-inner {
  width: 1360px;
  margin: 0 auto; }

@media only screen and (max-width: 1440px) {
  .wrap {
    margin: 0 40px;
    max-width: 100%; }
  .wrap-menu {
    margin: 0 40px 24px 40px;
    max-width: 100%; }
  .wrap-inner {
    width: 100%;
    margin: 0;
    padding: 0 40px; } }

@media only screen and (max-width: 992px) {
  .wrap {
    margin: 0 24px; }
  .wrap-menu,
  .wrap-inner {
    padding: 0 24px;
    margin: 0; } }

@media only screen and (max-width: 748px) {
  .wrap-small {
    width: 100%;
    padding: 0 24px; } }

@media only screen and (max-width: 576px) {
  .wrap {
    margin: 0 16px; }
  .wrap-small {
    padding: 0 16px; }
  .wrap-menu,
  .wrap-inner {
    padding: 0 16px;
    margin: 0; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

button {
  cursor: pointer;
  border: 0;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  text-transform: uppercase;
  text-align: center;
  font-weight: 700;
  padding: 1px 8px;
  border-radius: 45px;
  word-break: break-word;
  font-family: 'Open Sans', sans-serif;
  transition: 0.3s ease-in-out; }
  button:focus, button:active {
    outline: none; }

.primary-button,
.secondary-button {
  text-transform: uppercase;
  text-decoration: none;
  border-radius: 45px;
  font-size: 14px;
  background-color: transparent;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
  word-break: break-word;
  transition: 0.3s ease-in-out; }
  .primary-button:focus, .primary-button:active,
  .secondary-button:focus,
  .secondary-button:active {
    outline: none;
    transform: translateY(2px); }

.primary-button,
.primary-button:visited {
  border: 2px solid;
  padding: 8px 16px;
  min-width: 204px;
  max-width: 330px;
  min-height: 48px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  margin: 16px auto 0 auto; }
  .primary-button svg,
  .primary-button img,
  .primary-button:visited svg,
  .primary-button:visited img {
    margin-left: 8px;
    fill: transparent;
    transition: 0.3s ease-in-out; }

.primary-button,
.primary-button:visited,
.brand-page.blueColor .catalog-button,
.brand-page.blueColor .catalog-button:visited {
  border-color: #08315a;
  background-color: #08315a;
  color: #ffffff; }
  .primary-button:hover,
  .primary-button:visited:hover,
  .brand-page.blueColor .catalog-button:hover,
  .brand-page.blueColor .catalog-button:visited:hover {
    color: #08315a;
    background-color: #ffffff; }
  .primary-button svg,
  .primary-button img,
  .primary-button:visited svg,
  .primary-button:visited img,
  .brand-page.blueColor .catalog-button svg,
  .brand-page.blueColor .catalog-button img,
  .brand-page.blueColor .catalog-button:visited svg,
  .brand-page.blueColor .catalog-button:visited img {
    stroke: #ffffff;
    fill: transparent; }
  .primary-button:hover svg,
  .primary-button:visited:hover svg,
  .brand-page.blueColor .catalog-button:hover svg,
  .brand-page.blueColor .catalog-button:visited:hover svg {
    stroke: #08315a; }

.primary-button.blue-text,
.primary-button.blue-text:visited {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #08315a;
  margin: 0; }
  .primary-button.blue-text:hover,
  .primary-button.blue-text:visited:hover {
    background-color: #08315a;
    color: #ffffff; }

.primary-button.white-border,
.primary-button.white-border:visited {
  border-color: #ffffff;
  background-color: transparent;
  color: #ffffff;
  margin: 0; }
  .primary-button.white-border:hover,
  .primary-button.white-border:visited:hover {
    color: #000000;
    background-color: #ffffff; }

.primary-button.white-bg,
.primary-button.white-bg:visited {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #000000;
  margin: 0; }
  .primary-button.white-bg:hover,
  .primary-button.white-bg:visited:hover {
    color: #ffffff;
    background-color: transparent; }

.primary-button.black-border,
.primary-button.black-border:visited {
  border-color: #000000;
  background-color: transparent;
  color: #000000;
  margin: 0; }
  .primary-button.black-border:hover,
  .primary-button.black-border:visited:hover {
    color: #ffffff;
    background-color: #000000; }

.primary-button.yellow-black-border,
.primary-button.black-border:visited {
  border-color: #000000;
  background-color: transparent;
  color: #000000;
  margin: 0; }
  .primary-button.yellow-black-border:hover,
  .primary-button.black-border:visited:hover {
    color: #ffed00;
    background-color: #000000; }

.primary-button.red,
.primary-button.red:visited,
.brand-page.redColor .catalog-button,
.brand-page.redColor .catalog-button:visited {
  border-color: #fc5f20;
  background-color: #fc5f20;
  color: #ffffff; }
  .primary-button.red:hover,
  .primary-button.red:visited:hover,
  .brand-page.redColor .catalog-button:hover,
  .brand-page.redColor .catalog-button:visited:hover {
    background-color: #ffffff;
    color: #fc5f20; }
  .primary-button.red svg,
  .primary-button.red img,
  .primary-button.red:visited svg,
  .primary-button.red:visited img,
  .brand-page.redColor .catalog-button svg,
  .brand-page.redColor .catalog-button img,
  .brand-page.redColor .catalog-button:visited svg,
  .brand-page.redColor .catalog-button:visited img {
    stroke: #ffffff; }
  .primary-button.red:hover svg,
  .primary-button.red:visited:hover svg,
  .brand-page.redColor .catalog-button:hover svg,
  .brand-page.redColor .catalog-button:visited:hover svg {
    stroke: #fc5f20; }

.primary-button.red-text,
.primary-button.red-text:visited {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #fc5f20;
  margin: 0; }
  .primary-button.red-text:hover,
  .primary-button.red-text:visited:hover {
    border-color: #ffffff;
    background-color: #fc5f20;
    color: #ffffff; }

.primary-button.yellow,
.primary-button.yellow:visited {
  border-color: #ffed00;
  background-color: #ffed00;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  min-height: 24px;
  padding: 1px 8px;
  margin: 0; }
  .primary-button.yellow:hover,
  .primary-button.yellow:visited:hover {
    border-color: #ffed00;
    background-color: #ffffff !important;
    color: #000000 !important; }

.primary-button.yellow.background-black,
.primary-button.yellow:visited {
  border-color: #ffed00;
  background-color: #ffed00;
  color: #000000;
  font-size: 14px;
  font-weight: 700;
  min-height: 24px;
  padding: 1px 8px;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  max-width: 200px; }
  .primary-button.yellow.background-black:hover,
  .primary-button.yellow:visited:hover {
    border-color: #ffed00;
    background-color: #000000;
    color: #ffffff; }

.primary-button.disruptor,
.primary-button.disruptor:visited {
  border-color: #3ddbe0;
  color: #ffffff;
  background-color: #3ddbe0;
  font-size: 14px;
  font-weight: 400;
  min-height: 24px;
  padding: 1px 8px;
  margin: 0; }
  .primary-button.disruptor:hover,
  .primary-button.disruptor:visited:hover {
    background-color: #ffffff;
    color: #a4a4a4; }

.primary-button.black,
.primary-button.black:visited {
  border-color: #000000;
  background-color: #000000;
  color: #ffffff;
  padding: 8px 16px;
  min-height: 48px;
  margin: 0; }
  .primary-button.black:hover,
  .primary-button.black:visited:hover {
    background-color: #ffffff;
    color: #000000; }

.primary-button.small {
  min-width: 135px;
  padding: 1px 8px;
  min-height: 24px; }

.secondary-button {
  padding: 0 6px;
  border: none;
  font-weight: 400; }

.fit {
  max-width: 240px; }

.fullWidth {
  max-width: 100%; }

.nav-menu-btn {
  min-width: 24px;
  min-height: 20px;
  position: relative;
  margin: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer; }
  .nav-menu-btn span {
    display: block;
    position: absolute;
    height: 1.5px;
    width: 100%;
    background: #000000;
    border-radius: 30%;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out; }
  .nav-menu-btn span:nth-child(1) {
    top: 0; }
  .nav-menu-btn span:nth-child(2),
  .nav-menu-btn span:nth-child(3) {
    top: 8px; }
  .nav-menu-btn span:nth-child(4) {
    top: 16px; }
  .nav-menu-btn.open span:nth-child(1) {
    top: 18px;
    width: 0;
    left: 50%; }
  .nav-menu-btn.open span:nth-child(2) {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg); }
  .nav-menu-btn.open span:nth-child(3) {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg); }
  .nav-menu-btn.open span:nth-child(4) {
    top: 18px;
    width: 0;
    left: 50%; }

a.primary-button.yellow.background-grey,
a.primary-button.yellow.background-grey:visited {
  border-color: #ffed00;
  background-color: #ffed00;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  min-height: 24px;
  padding: 1px 8px;
  margin: 0;
  text-align: center;
  display: flex;
  justify-content: center !important;
  max-width: 300px; }
  a.primary-button.yellow.background-grey:hover,
  a.primary-button.yellow.background-grey:visited:hover {
    border-color: #ffed00;
    background-color: #f6f6f6;
    font-weight: 900; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  button,
  .button,
  .primary-button {
    padding: 13px; } }

@media only screen and (max-width: 992px) {
  .primary-button {
    min-width: 204px;
    max-width: 330px; }
  .fit {
    max-width: 240px; }
  .fullWidth {
    max-width: 100%; } }

@media only screen and (max-width: 576px) {
  .primary-button,
  .fit,
  .fullWidth {
    max-width: 100%; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

header {
  border-bottom: 1px solid #d8d8d8;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 99;
  background-color: #ffffff;
  min-height: 150px; }

/* First row with logo and social icons */
.logo-social-row {
  padding: 24px 0;
  /* Mobile right*/ }
  .logo-social-row .logo {
    max-width: 265px; }
  .logo-social-row .search-menu {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    position: unset; }

/* Second row with navigation menu, search and login*/
.login .primary-button {
  font-weight: 700; }

.navigation-login-row .login-search-social {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  text-transform: uppercase; }

.navigation-login-row .search {
  margin-right: 16px; }

/* Search */
.header-search.desktop form,
.header-search.tablet.laptop form,
.header-search.mobile form {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  position: relative; }
  .header-search.desktop form input,
  .header-search.tablet.laptop form input,
  .header-search.mobile form input {
    border: 0;
    background-color: transparent;
    line-height: 1; }
    .header-search.desktop form input:focus,
    .header-search.tablet.laptop form input:focus,
    .header-search.mobile form input:focus {
      outline: none; }
  .header-search.desktop form ::placeholder,
  .header-search.tablet.laptop form ::placeholder,
  .header-search.mobile form ::placeholder {
    color: #000000;
    opacity: 1;
    /* Firefox */ }
  .header-search.desktop form .header-search-input,
  .header-search.tablet.laptop form .header-search-input,
  .header-search.mobile form .header-search-input {
    text-align: right;
    text-transform: uppercase;
    margin-right: 6px;
    border: 2px solid #ffffff;
    padding: 2px 30px 2px 8px;
    border-radius: 45px;
    max-width: 120px;
    transition: all 0.7s ease-in-out; }
    .header-search.desktop form .header-search-input:hover, .header-search.desktop form .header-search-input:focus,
    .header-search.tablet.laptop form .header-search-input:hover,
    .header-search.tablet.laptop form .header-search-input:focus,
    .header-search.mobile form .header-search-input:hover,
    .header-search.mobile form .header-search-input:focus {
      background-color: #f6f6f6;
      border-color: #f6f6f6;
      max-width: 160px; }
  .header-search.desktop form .header-submit,
  .header-search.tablet.laptop form .header-submit,
  .header-search.mobile form .header-submit {
    position: absolute;
    right: 14px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTciIHZpZXdCb3g9IjAgMCAxNiAxNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNDA3NzMgMTIuNjQ3NUMxMC4wMjYgMTIuNjQ3NSAxMi4xNDg1IDEwLjUyNSAxMi4xNDg1IDcuOTA2NzZDMTIuMTQ4NSA1LjI4ODUyIDEwLjAyNiAzLjE2NjAyIDcuNDA3NzMgMy4xNjYwMkM0Ljc4OTQ5IDMuMTY2MDIgMi42NjY5OSA1LjI4ODUyIDIuNjY2OTkgNy45MDY3NkMyLjY2Njk5IDEwLjUyNSA0Ljc4OTQ5IDEyLjY0NzUgNy40MDc3MyAxMi42NDc1WiIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTMuMzMzNiAxMy44MzM2TDEwLjc1NTkgMTEuMjU1OSIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K);
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    width: 16px;
    height: 16px;
    padding: 0;
    border-radius: 4px;
    transition: all 0.3s ease-in-out; }

.header-search.mobile.tablet {
  margin-right: 32px; }
  .header-search.mobile.tablet button {
    padding: 0; }
  .header-search.mobile.tablet .header-form-search {
    display: none;
    background-color: #ffffff;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 999;
    border-bottom: 1px solid #D8D8D8; }
  .header-search.mobile.tablet form .header-search-input {
    border: 2px solid #d8d8d8;
    padding: 8px 30px 8px 16px;
    margin: 16px;
    width: 100%;
    max-width: 100%;
    text-align: left; }
    .header-search.mobile.tablet form .header-search-input:hover, .header-search.mobile.tablet form .header-search-input:focus {
      background-color: #f6f6f6;
      max-width: 100%; }
  .header-search.mobile.tablet form .header-submit {
    position: absolute;
    right: 30px; }

/* Social icons + language */
.social-icons-language {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center; }
  .social-icons-language > *:not(:last-child) {
    margin-right: 16px; }
  .social-icons-language .social-icon {
    height: 16px;
    opacity: 0.3;
    display: flex;
    flex-flow: row nowrap; }
    .social-icons-language .social-icon:hover {
      opacity: 1; }

.social-icons-language .divider {
  margin-right: 16px;
  color: #a4a4a4; }

/* Language dropdown */
.language {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  cursor: pointer; }
  .language .language-dropdown {
    border: 0;
    font-size: 14px;
    color: #a4a4a4;
    outline: none;
    transition: 0.3s ease-in-out;
    min-width: 20px; }
    .language .language-dropdown div {
      visibility: hidden;
      opacity: 0;
      position: absolute;
      min-width: 40px;
      text-align: center;
      border: 1px solid #d8d8d8;
      z-index: 99;
      background-color: white;
      transition: all 0.3s ease-in-out;
      top: 120%;
      left: 9px;
      transform: translate(-50%, 0);
      border-radius: 4px;
      overflow: hidden;
      padding: 3px; }
      .language .language-dropdown div a {
        display: block;
        color: #a4a4a4;
        line-height: 24px;
        border-radius: 3px;
        transition: all 0.3s ease-in-out; }
        .language .language-dropdown div a:hover {
          color: black;
          background-color: #d8d8d8; }
      .language .language-dropdown div a:first-of-type {
        margin-bottom: 3px; }
  .language:focus {
    border: 0; }
  .language:hover .language-dropdown div {
    visibility: visible;
    opacity: 1; }
  .language:hover .language-dropdown > span {
    color: #000000;
    font-weight: 700; }
  .language:hover .lang-toggle-arrow span {
    background-color: #000000; }

/* For IE */
.language-dropdown::-ms-expand {
  display: none; }

@media screen and (min-width: 0 \0 ) {
  select {
    background: none \9;
    padding: 5px \9; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header-search.desktop form .header-search-input,
  .header-search.mobile form .header-search-input,
  .header-search.tablet.laptop form .header-search-input {
    width: 160px; }
  .header-search.desktop form .header-submit,
  .header-search.mobile form .header-submit,
  .header-search.tablet.laptop form .header-submit {
    top: 5px; } }

@media only screen and (max-width: 992px) {
  header {
    min-height: 89px; }
  .logo-social-row .logo {
    max-width: 200px; }
  /* Second row mobile */
  .navigation-login-row {
    background-color: #fff;
    display: none;
    position: fixed;
    z-index: 998;
    margin: 0;
    top: 89px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll; }
    .navigation-login-row > div {
      height: 100%;
      align-content: space-between; }
    .navigation-login-row .login-search-social {
      justify-content: space-between;
      padding-top: 40px;
      padding-bottom: 40px;
      border-top: 1px solid #d8d8d8; }
    .navigation-login-row .social-icons-language {
      justify-content: flex-end; }
      .navigation-login-row .social-icons-language > a {
        margin-right: 16px; } }

.wrap-menu .main-dialog {
  display: none; }

@media only screen and (max-width: 1200px) {
  .social-icons-language {
    align-items: center; }
  .navigation-login-row .login-search-social {
    display: none; } }

@media only screen and (max-width: 992px) {
  .wrap-menu .main-dialog {
    display: initial !important; }
  /* First row with logo and social icons */
  .logo-social-row {
    /* To be removed after beta test banner is removed from the site*/ }
    .logo-social-row .logo {
      margin-left: 16px; }
  .navigation-login-row .login-search-social {
    display: flex; }
    .navigation-login-row .login-search-social .login {
      margin-right: 16px; }
  /* Language dropdown */
  .language .language-dropdown div {
    top: unset;
    bottom: 120%; } }

@media only screen and (max-width: 768px) {
  .navigation-login-row .login-search-social {
    padding-top: 24px;
    padding-bottom: 24px; } }

@media only screen and (max-width: 576px) {
  header {
    min-height: 73px; }
    header .logo-social-row {
      padding: 16px 0; }
    header .navigation-login-row {
      top: 73px; }
  .header-search.mobile.tablet {
    margin-right: 16px; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

footer {
  background-color: #000000;
  z-index: 99; }
  footer p.heading {
    font-size: 14px;
    font-weight: 700;
    margin: 0 0 16px 0;
    color: #ffffff;
    text-transform: uppercase; }
  footer a {
    text-transform: lowercase; }
  footer p:not(.heading) {
    color: rgba(255, 255, 255, 0.5);
    margin: 0; }
  footer .first-row {
    padding: 40px 0; }
    footer .first-row .logo {
      max-width: 265px; }
  footer .second-row,
  footer .second-third-column {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between; }
    footer .second-row > div:not(:last-child),
    footer .second-third-column > div:not(:last-child) {
      margin-right: 16px; }
    footer .second-row .first-column,
    footer .second-third-column .first-column {
      max-width: 371px; }
      footer .second-row .first-column p:not(.heading),
      footer .second-third-column .first-column p:not(.heading) {
        line-height: 1.65;
        margin-bottom: 16px; }
      footer .second-row .first-column a,
      footer .second-third-column .first-column a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-size: 14px; }
        footer .second-row .first-column a svg,
        footer .second-third-column .first-column a svg {
          margin-left: 8px;
          stroke: #ffffff;
          transition: 0.3s ease-in-out; }
        footer .second-row .first-column a:hover svg,
        footer .second-third-column .first-column a:hover svg {
          stroke: #ffffff; }
    footer .second-row .second-column,
    footer .second-third-column .second-column {
      min-width: 210px; }
      footer .second-row .second-column p:not(.heading):not(.phone):not(.email),
      footer .second-third-column .second-column p:not(.heading):not(.phone):not(.email) {
        line-height: 1.65; }
      footer .second-row .second-column .phone,
      footer .second-row .second-column .email,
      footer .second-third-column .second-column .phone,
      footer .second-third-column .second-column .email {
        font-size: 14px; }
      footer .second-row .second-column .phone,
      footer .second-third-column .second-column .phone {
        margin-bottom: 8px; }
      footer .second-row .second-column .email,
      footer .second-third-column .second-column .email {
        margin: 0 0 16px 0; }
    footer .second-row .third-column,
    footer .second-third-column .third-column {
      min-width: 110px; }
    footer .second-row .social-icons,
    footer .second-third-column .social-icons {
      display: flex;
      flex-flow: row wrap; }
      footer .second-row .social-icons a,
      footer .second-third-column .social-icons a {
        opacity: 0.7; }
        footer .second-row .social-icons a:hover,
        footer .second-third-column .social-icons a:hover {
          opacity: 1; }
      footer .second-row .social-icons a:not(:last-child),
      footer .second-third-column .social-icons a:not(:last-child) {
        margin-right: 24px; }
  footer .second-row {
    padding-bottom: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3); }
  footer .third-row {
    padding: 24px 0; }
    footer .third-row a {
      font-size: 14px;
      color: #ffffff;
      text-decoration: none;
      text-transform: uppercase; }
      footer .third-row a:hover {
        color: #ffffff; }
      footer .third-row a:not(:last-child) {
        margin-right: 46px; }

@media only screen and (max-width: 992px) {
  footer .second-row {
    flex-flow: row wrap;
    padding-bottom: 40px; }
    footer .second-row > div:not(:last-child) {
      margin-right: 0;
      margin-bottom: 40px; }
    footer .second-row .second-third-column {
      flex: 1 100%;
      justify-content: flex-start; } }

@media only screen and (max-width: 576px) {
  footer .first-row,
  footer .third-row {
    padding-top: 32px; }
  footer .first-row,
  footer .second-row,
  footer .third-row {
    padding-bottom: 32px; }
  footer .second-row,
  footer .second-third-column {
    flex-flow: row wrap; }
    footer .second-row > div,
    footer .second-third-column > div {
      flex: 1 100%; }
    footer .second-row > div:not(:last-child),
    footer .second-third-column > div:not(:last-child) {
      margin-right: 0;
      margin-bottom: 32px; }
  footer .third-row nav {
    display: flex;
    flex-flow: column; }
    footer .third-row nav a:not(:last-child) {
      padding-bottom: 16px; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  /* Design images*/
  .design-images .img-btn {
    position: relative;
    flex: 50%;
    width: 50%; }
    .design-images .img-btn:hover {
      flex: 50% !important; }
  /* Youtube video */
  .youtube-video {
    height: 600px !important; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

/* Main carousel dots color options */
.main-carousel.darkColor .slick-dots li button:before {
  color: #000000; }

.main-carousel.darkColor .slick-dots li.slick-active button:before {
  color: #000000; }

.main-carousel.lightColor .slick-dots li button:before {
  color: #ffffff; }

.main-carousel.lightColor .slick-dots li.slick-active button:before {
  color: #ffffff; }

/* General style */
.main-carousel,
.cards-carousel {
  position: relative;
  /* Slick buttons preview and next slider */
  /* Slick dots */ }
  .main-carousel button.slick-prev,
  .main-carousel button.slick-next,
  .cards-carousel button.slick-prev,
  .cards-carousel button.slick-next {
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    height: 32px;
    width: 32px;
    margin: 7px;
    cursor: pointer;
    z-index: 1;
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    transition: 0.3s ease-in-out; }
    .main-carousel button.slick-prev:hover,
    .main-carousel button.slick-next:hover,
    .cards-carousel button.slick-prev:hover,
    .cards-carousel button.slick-next:hover {
      background-color: #ffed00; }
  .main-carousel button.slick-prev,
  .cards-carousel button.slick-prev {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDEzTDEgN0w3IDEiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==);
    left: 40px;
    transform: translate(-50%, 0); }
  .main-carousel button.slick-next,
  .cards-carousel button.slick-next {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjk5OTk5OSAxM0w3IDdMMSAxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=);
    right: 40px;
    transform: translate(50%, 0); }
  .main-carousel .slick-dots,
  .cards-carousel .slick-dots {
    position: absolute;
    bottom: 40px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%; }
    .main-carousel .slick-dots li,
    .cards-carousel .slick-dots li {
      position: relative;
      display: inline-block;
      height: 10px;
      width: 10px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer; }
      .main-carousel .slick-dots li button,
      .cards-carousel .slick-dots li button {
        border: 0;
        background: transparent;
        display: block;
        height: 10px;
        width: 10px;
        outline: none;
        line-height: 0;
        font-size: 0;
        color: transparent;
        padding: 5px;
        cursor: pointer; }
        .main-carousel .slick-dots li button:hover, .main-carousel .slick-dots li button:focus,
        .cards-carousel .slick-dots li button:hover,
        .cards-carousel .slick-dots li button:focus {
          outline: none; }
          .main-carousel .slick-dots li button:hover:before, .main-carousel .slick-dots li button:focus:before,
          .cards-carousel .slick-dots li button:hover:before,
          .cards-carousel .slick-dots li button:focus:before {
            opacity: 1; }
        .main-carousel .slick-dots li button:before,
        .cards-carousel .slick-dots li button:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "\2022";
          width: 10px;
          height: 10px;
          font-size: 34px;
          line-height: 20px;
          text-align: center;
          color: #000000;
          opacity: 0.2;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          display: flex;
          align-items: center;
          justify-content: center; }
      .main-carousel .slick-dots li.slick-active button:before,
      .cards-carousel .slick-dots li.slick-active button:before {
        color: #000000;
        opacity: 1; }

/* Main carousel */
.main-carousel picture img {
  width: 100%;
  height: calc(100vh - 151px);
  min-height: 400px;
  max-height: 800px;
  object-fit: cover;
  object-position: 50% 60%; }

.main-carousel .carousel-slide {
  position: relative; }

.main-carousel .slider-content {
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  padding-top: 32px;
  padding-bottom: 32px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  align-items: flex-start; }
  .main-carousel .slider-content .top-content {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 16px; }
    .main-carousel .slider-content .top-content img {
      margin-right: 13%;
      margin-bottom: 40px;
      max-width: 200px; }
  .main-carousel .slider-content .bottom-content {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: space-between; }
    .main-carousel .slider-content .bottom-content > *:last-child {
      margin-bottom: 0; }
  .main-carousel .slider-content h1.slide-heading {
    font-weight: 700; }
  .main-carousel .slider-content h1, .main-carousel .slider-content h2, .main-carousel .slider-content h3 {
    line-height: 1;
    margin: 0; }
  .main-carousel .slider-content h2 {
    margin-left: 40px; }
  .main-carousel .slider-content h3 {
    margin-bottom: 16px;
    max-width: 440px; }
  .main-carousel .slider-content .slide-text {
    max-width: 380px;
    line-height: 1.65;
    margin-bottom: 16px; }

/* Cards carousel*/
.cards-carousel {
  margin-bottom: 40px;
  /* Sliders */
  /* Slick dots */
  /* Slick buttons preview and next slider */ }
  .cards-carousel picture img {
    max-height: 552px;
    max-width: 100%;
    border-radius: 4px; }
  .cards-carousel .slick-slide {
    margin: 0 24px 24px 0; }
  .cards-carousel .slick-track {
    display: flex;
    align-items: center; }
  .cards-carousel .slick-dots {
    bottom: -10px; }
  .cards-carousel button.slick-prev,
  .cards-carousel button.slick-next {
    top: 43%; }
  .cards-carousel-thumbnails {
    margin: 0 auto;
    text-align: center; }
    .cards-carousel-thumbnails-item {
      background-color: #000000;
      border-radius: 5px;
      display: inline-block;
      height: 90px;
      overflow: hidden;
      width: 130px; }
      .cards-carousel-thumbnails-item:hover img {
        opacity: 0.5; }
      .cards-carousel-thumbnails-item img {
        border-radius: 5px;
        height: auto;
        object-fit: cover;
        transition: all 0.2s ease-in-out;
        width: 100%; }
    .cards-carousel-thumbnails .slick-slide {
      margin-right: 24px; }

/* Page Main Color Options */
.blueColor .main-carousel,
.blueColor .cards-carousel,
.redColor .main-carousel,
.redColor .cards-carousel {
  /* Slick buttons preview and next slider */ }
  .blueColor .main-carousel button.slick-prev:hover,
  .blueColor .cards-carousel button.slick-prev:hover,
  .redColor .main-carousel button.slick-prev:hover,
  .redColor .cards-carousel button.slick-prev:hover {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik03IDEzTDEgN0w3IDEiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==); }
  .blueColor .main-carousel button.slick-next:hover,
  .blueColor .cards-carousel button.slick-next:hover,
  .redColor .main-carousel button.slick-next:hover,
  .redColor .cards-carousel button.slick-next:hover {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOCIgaGVpZ2h0PSIxNCIgdmlld0JveD0iMCAwIDggMTQiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0wLjk5OTk5OSAxM0w3IDdMMSAxIiBzdHJva2U9IndoaXRlIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cjwvc3ZnPgo=); }

.blueColor .main-carousel,
.blueColor .cards-carousel {
  /* Slick buttons preview and next slider */ }
  .blueColor .main-carousel button.slick-prev:hover,
  .blueColor .main-carousel button.slick-next:hover,
  .blueColor .cards-carousel button.slick-prev:hover,
  .blueColor .cards-carousel button.slick-next:hover {
    background-color: #08315a; }

.redColor .main-carousel,
.redColor .cards-carousel {
  /* Slick buttons preview and next slider */ }
  .redColor .main-carousel button.slick-prev:hover,
  .redColor .main-carousel button.slick-next:hover,
  .redColor .cards-carousel button.slick-prev:hover,
  .redColor .cards-carousel button.slick-next:hover {
    background-color: #fc5f20; }

@media only screen and (max-width: 992px) {
  .cards-carousel picture img {
    max-height: 400px; }
  .main-carousel {
    /* Slick dots */ }
    .main-carousel picture img {
      height: calc(100vh - 88px);
      max-height: 580px; }
    .main-carousel .slick-dots {
      bottom: 32px; } }

@media only screen and (max-width: 768px) {
  .cards-carousel .slick-slide {
    margin: 0 8px 24px 0; }
  .cards-carousel picture img {
    max-height: 328px; }
  .main-carousel .slider-content {
    /* Slick dots */ }
    .main-carousel .slider-content .top-content img {
      max-width: 140px; }
    .main-carousel .slider-content > div div {
      flex: 1 100%; }
    .main-carousel .slider-content h2 {
      margin-left: 24px; }
    .main-carousel .slider-content .slick-dots {
      bottom: 24px; } }

@media only screen and (max-width: 576px) {
  .main-carousel {
    /* Slick dots */ }
    .main-carousel picture img {
      height: calc(100vh - 72px); }
    .main-carousel .slick-dots {
      bottom: 16px; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

.navigation {
  position: inherit !important;
  /* Children menu */
  /* Rim application */ }
  .navigation ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-flow: row wrap;
    list-style-type: none;
    padding: 0;
    z-index: 2;
    background-color: #ffffff; }
  .navigation ul.primary {
    margin: 0; }
    .navigation ul.primary > li {
      display: flex;
      flex-flow: row wrap;
      cursor: pointer;
      align-items: center;
      max-width: 100%; }
      .navigation ul.primary > li:not(:last-child) {
        margin-right: 24px; }
      .navigation ul.primary > li a {
        flex-flow: column;
        align-items: flex-start;
        text-decoration: none;
        margin-bottom: 0; }
        .navigation ul.primary > li a.active-page {
          color: #000000; }
          .navigation ul.primary > li a.active-page:hover {
            color: #000000; }
      .navigation ul.primary > li .nav-title {
        text-transform: uppercase;
        margin-bottom: 0;
        text-align: center;
        transition: 0.2s ease-in-out; }
        .navigation ul.primary > li .nav-title::before {
          display: block;
          content: attr(title);
          font-weight: bold;
          height: 0;
          overflow: hidden;
          visibility: hidden; }
      .navigation ul.primary > li .nav-title.title-bold,
      .navigation ul.primary > li > a.active-page > .nav-title:first-of-type {
        font-weight: 700;
        color: #000000; }
      .navigation ul.primary > li:hover > .nav-title,
      .navigation ul.primary > li:hover > a > .nav-title,
      .navigation ul.primary > li:hover > .nav-title.title-bold,
      .navigation ul.primary > li:hover > a > .nav-title.title-bold,
      .navigation ul.primary > li:hover > a.active-page > .nav-title:first-of-type {
        color: #000000;
        font-weight: 700; }
      .navigation ul.primary > li:hover .nav-toggle-arrow span {
        background-color: #000000; }
      .navigation ul.primary > li li .nav-title {
        text-align: left; }
  .navigation .drop-down {
    flex: 1 100%;
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #ffffff;
    z-index: 2;
    padding: 32px 0;
    border-bottom: 1px solid #d8d8d8;
    border-top: 1px solid #d8d8d8;
    max-width: 100%;
    /* Scroll tab */ }
    .navigation .drop-down ::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      padding: 3px; }
    .navigation .drop-down ::-webkit-scrollbar-track {
      background: #f1f1f1; }
    .navigation .drop-down ::-webkit-scrollbar-thumb {
      background: #08315a; }
    .navigation .drop-down ::-webkit-scrollbar-thumb:hover {
      background: #ffed00; }
    .navigation .drop-down > div {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: flex-start;
      overflow-y: auto;
      max-height: 60vh;
      padding-bottom: 8px; }
  .navigation ul.secondary {
    flex-flow: row nowrap; }
    .navigation ul.secondary > li {
      max-width: 329px; }
      .navigation ul.secondary > li:not(:last-child) {
        margin-right: 16px; }
  .navigation .nav-img,
  .navigation .rim-app-img {
    border-radius: 4px;
    margin-bottom: 8px; }
  .navigation .nav-text {
    color: #a4a4a4;
    margin: 8px 0 0 0;
    font-size: 15px;
    line-height: 1.6; }
  .navigation .rim-app {
    margin-left: 16px;
    min-height: 310px;
    max-width: 329px; }
    .navigation .rim-app .rim-text,
    .navigation .rim-app .rim-link {
      text-transform: uppercase;
      font-size: 14px; }
    .navigation .rim-app .rim-text {
      margin-bottom: 16px; }
    .navigation .rim-app .rim-link-more {
      font-weight: 700;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: flex-start; }
      .navigation .rim-app .rim-link-more svg {
        margin-left: 8px;
        stroke: #000000;
        transition: 0.3s ease-in-out; }
    .navigation .rim-app:hover .rim-link-more svg {
      stroke: #ffed00; }
    .navigation .rim-app:hover .rim-text {
      color: #000000; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navigation .drop-down {
    top: 151px; } }

@media only screen and (max-width: 1200px) {
  .navigation ul.primary > li {
    padding: 6px 0; } }

@media only screen and (max-width: 992px) {
  .navigation {
    padding-top: 40px; }
    .navigation ul.primary {
      flex-flow: column;
      overflow-x: hidden; }
      .navigation ul.primary > li,
      .navigation ul.primary > li:not(:last-child) {
        margin: 0 0 40px 0;
        padding: 0; }
        .navigation ul.primary > li a,
        .navigation ul.primary > li:not(:last-child) a {
          margin-bottom: 0; }
      .navigation ul.primary > li.opacity .nav-title,
      .navigation ul.primary > li.opacity .nav-toggle-arrow {
        opacity: 0.3; }
      .navigation ul.primary > li:hover > .nav-title,
      .navigation ul.primary > li:hover > a.active-page > .nav-title {
        color: #000000; }
      .navigation ul.primary > li:hover > .nav-toggle-arrow > span {
        background-color: #000000; }
    .navigation .drop-down {
      position: static;
      padding: 32px 0 0 0;
      border: 0; }
    .navigation ul.secondary {
      margin: 0; }
      .navigation ul.secondary > li a .nav-title {
        margin-bottom: 8px; } }

@media only screen and (max-width: 768px) {
  .navigation {
    padding-top: 24px; }
    .navigation ul.primary > li:not(:last-child) {
      margin-bottom: 24px; }
    .navigation ul.secondary {
      flex-flow: column; }
      .navigation ul.secondary > li {
        margin-bottom: 24px; }
        .navigation ul.secondary > li:last-child {
          margin-bottom: 0; }
        .navigation ul.secondary > li a .nav-title {
          margin-bottom: 0; }
        .navigation ul.secondary > li .nav-img,
        .navigation ul.secondary > li .nav-text {
          display: none; }
    .navigation .rim-app {
      margin: 24px 16px 0 0;
      min-height: auto; }
      .navigation .rim-app .rim-app-img,
      .navigation .rim-app .rim-text,
      .navigation .rim-app .rim-link svg {
        display: none; }
      .navigation .rim-app .rim-link {
        font-weight: 400; }
    .navigation .drop-down {
      padding-top: 24px;
      padding-bottom: 0; }
      .navigation .drop-down > div {
        flex-flow: column;
        overflow: visible; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

.nav-toggle-arrow,
.accordion-arrow,
.filter-arrow,
.language .lang-toggle-arrow {
  width: 14.4px;
  min-width: 14.4px;
  height: 14.4px;
  position: relative;
  margin: 0 0 0 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out; }
  .nav-toggle-arrow span,
  .accordion-arrow span,
  .filter-arrow span,
  .language .lang-toggle-arrow span {
    top: 6px;
    position: absolute;
    width: 9px;
    height: 1.5px;
    background-color: #000;
    display: inline-block;
    transition: 0.2s ease-in-out; }
    .nav-toggle-arrow span:first-of-type,
    .accordion-arrow span:first-of-type,
    .filter-arrow span:first-of-type,
    .language .lang-toggle-arrow span:first-of-type {
      left: 0;
      transform: rotate(45deg); }
    .nav-toggle-arrow span:last-of-type,
    .accordion-arrow span:last-of-type,
    .filter-arrow span:last-of-type,
    .language .lang-toggle-arrow span:last-of-type {
      right: 0;
      transform: rotate(-45deg); }
  .nav-toggle-arrow.arrow-active span:first-of-type,
  .accordion-arrow.arrow-active span:first-of-type,
  .filter-arrow.arrow-active span:first-of-type,
  .language .lang-toggle-arrow.arrow-active span:first-of-type {
    transform: rotate(-45deg); }
  .nav-toggle-arrow.arrow-active span:last-of-type,
  .accordion-arrow.arrow-active span:last-of-type,
  .filter-arrow.arrow-active span:last-of-type,
  .language .lang-toggle-arrow.arrow-active span:last-of-type {
    transform: rotate(45deg); }

.yellow-circle-arrow,
.blue-circle-arrow {
  height: 32px;
  width: 32px;
  min-width: 32px;
  min-height: 32px;
  border-radius: 50%;
  background-color: #ffed00;
  margin: 0 0 0 8px;
  position: relative;
  transition: 0.3s ease-in-out; }
  .yellow-circle-arrow img,
  .yellow-circle-arrow .accordion-arrow,
  .blue-circle-arrow img,
  .blue-circle-arrow .accordion-arrow {
    margin-left: 0;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%); }

.blue-circle-arrow {
  height: 24px;
  width: 24px;
  min-width: 24px;
  min-height: 24px;
  background-color: #08315a; }

.language:hover .language-dropdown ~ .lang-toggle-arrow span:first-of-type {
  transform: rotate(-45deg); }

.language:hover .language-dropdown ~ .lang-toggle-arrow span:last-of-type {
  transform: rotate(45deg); }

.language .lang-toggle-arrow span {
  background-color: #a4a4a4; }

@media only screen and (max-width: 992px) {
  .nav-toggle-arrow {
    min-width: 20px;
    min-height: 24px;
    margin: 0 0 0 16px; }
    .nav-toggle-arrow span {
      top: 13px;
      width: 12px; }
  .language .lang-toggle-arrow span:first-of-type {
    left: 0;
    transform: rotate(-45deg); }
  .language .lang-toggle-arrow span:last-of-type {
    right: 0;
    transform: rotate(45deg); }
  .language:hover .language-dropdown ~ .lang-toggle-arrow span:first-of-type {
    transform: rotate(45deg); }
  .language:hover .language-dropdown ~ .lang-toggle-arrow span:last-of-type {
    transform: rotate(-45deg); } }

a {
  color: #000000;
  text-decoration: none;
  word-break: break-word;
  transition: 0.3s ease-in-out; }
  a:focus, a:active, a:visited {
    color: #000000; }
  a:hover {
    color: #000000;
    font-weight: 900; }

a.reverse p.nav-title:hover {
  color: #000000;
  font-weight: bold; }

a.reverse {
  color: #000000; }
  a.reverse:focus, a.reverse:active, a.reverse:visited {
    color: #000000; }
  a.reverse:hover {
    color: #000000; }

a.reverse-white,
a.reverse-white-opacity {
  color: #ffffff; }
  a.reverse-white:focus, a.reverse-white:active, a.reverse-white:visited,
  a.reverse-white-opacity:focus,
  a.reverse-white-opacity:active,
  a.reverse-white-opacity:visited {
    color: #ffffff; }
  a.reverse-white:hover,
  a.reverse-white-opacity:hover {
    color: #ffffff; }

a.reverse-white-opacity {
  color: rgba(255, 255, 255, 0.5); }
  a.reverse-white-opacity:focus, a.reverse-white-opacity:active, a.reverse-white-opacity:visited {
    color: rgba(255, 255, 255, 0.5); }

a.no-hover {
  color: #000000; }
  a.no-hover:focus, a.no-hover:active, a.no-hover:visited {
    color: #000000; }

.box-links {
  display: flex;
  flex-flow: column;
  transition: 0.3s ease-in-out; }
  .box-links a {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    padding: 16px;
    font-weight: 700;
    border-radius: 8px;
    overflow: hidden; }
    .box-links a:not(:last-child) {
      margin-bottom: 8px; }
    .box-links a .yellow-circle-arrow {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      transition: 0.3s ease-in-out; }
      .box-links a .yellow-circle-arrow svg {
        stroke: #000000;
        transition: 0.3s ease-in-out; }
    .box-links a:hover,
    .box-links a a:hover {
      background-color: #d8d8d8; }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

table {
  width: 100%;
  word-break: break-word;
  table-layout: fixed; }
  table tr:first-child th {
    border-top: 1px solid #000000; }
  table tr:not(:last-child) th,
  table tr:not(:last-child) td {
    border-bottom: 1px solid #d8d8d8; }
  table tr th,
  table tr td {
    padding: 16px 8px;
    line-height: initial;
    text-align: left; }
    table tr th p:last-child,
    table tr td p:last-child {
      margin-bottom: 0; }
  table tr th {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5); }
    table tr th:not(:last-child) {
      border-right: 1px solid #d8d8d8; }
  table tr td:not(:last-child) {
    border-right: 1px solid #d8d8d8; }

@media only screen and (max-width: 576px) {
  table tr th,
  table tr td {
    padding: 8px 6px; } }

/* Pages
--------------------------------------------- */
.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

/* Components */
.brandimages {
  overflow: hidden;
  position: relative;
  transition: all 0.7s ease-in;
  opacity: 1;
  animation: 2s fadeIn;
  max-width: 2560px; }
  @media screen and (min-width: 1024px) {
    .brandimages {
      height: calc(100vh - 293px);
      max-height: 728px;
      min-height: 400px;
      overflow: hidden; } }
  .brandimages-wrapper {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1024px) {
      .brandimages-wrapper {
        flex-direction: row;
        left: -6%;
        position: absolute;
        width: 112%; } }
    @media screen and (min-width: 1440px) {
      .brandimages-wrapper {
        width: 110%;
        left: -5%; } }
  .brandimages-brand {
    background-color: #000;
    border-bottom: 3px solid white;
    height: calc(24vh);
    overflow: hidden;
    position: relative;
    transition: 0.8s ease-out;
    width: 100%; }
    @media screen and (min-width: 1024px) {
      .brandimages-brand {
        border-right: 3px solid white;
        flex: 33.3333%;
        height: 100%;
        transform: skew(-13deg);
        width: 33.33333%; } }
    @media screen and (max-width: 1023px) {
      .brandimages-brand.active {
        height: 34vh; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand.active {
        flex: 45% !important; } }
    .brandimages-brand.active .brandcontent {
      opacity: 1;
      height: 255px;
      top: 30px; }
      @media screen and (min-width: 1024px) {
        .brandimages-brand.active .brandcontent {
          height: 175px;
          top: auto;
          bottom: 50px; } }
      .brandimages-brand.active .brandcontent-button {
        opacity: 1; }
        .brandimages-brand.active .brandcontent-button--initial {
          opacity: 0; }
        .brandimages-brand.active .brandcontent-button--top {
          bottom: 50px;
          opacity: 1; }
          @media screen and (min-width: 1024px) {
            .brandimages-brand.active .brandcontent-button--top {
              bottom: 0; } }
        .brandimages-brand.active .brandcontent-button--bottom {
          bottom: 0; }
          @media screen and (min-width: 1024px) {
            .brandimages-brand.active .brandcontent-button--bottom {
              bottom: 44px; } }
    .brandimages-brand.active img {
      opacity: 0.7; }
      @media screen and (max-width: 1023px) {
        .brandimages-brand.active img {
          top: -35%;
          height: 150%;
          width: 150%; } }
    @media screen and (max-width: 1023px) {
      .brandimages-brand.small {
        height: 20vh; } }
    .brandimages-brand.small .brandcontent {
      opacity: 0; }
      @media screen and (max-width: 1023px) {
        .brandimages-brand.small .brandcontent {
          top: 50px; } }
      .brandimages-brand.small .brandcontent-button--initial {
        opacity: 1; }
    .brandimages-brand.small img {
      opacity: 1; }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(1) {
        width: 40%; } }
    .brandimages-brand:nth-child(1).active img {
      object-position: 35px 63%;
      width: 215%;
      left: -50%; }
      @media screen and (min-width: 1024px) {
        .brandimages-brand:nth-child(1).active img {
          object-position: 35px 73%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(1) .brandcontent {
        left: 33%;
        width: 65%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(1) .brandcontent-button--initial {
        left: 33%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(2) .brandcontent,
      .brandimages-brand:nth-child(2) .brandcontent-button--initial {
        left: 7%; } }
    @media screen and (max-width: 1023px) {
      .brandimages-brand:nth-child(2) img {
        object-position: 113px 65%;
        width: 180%; } }
    .brandimages-brand:nth-child(2).active img {
      object-position: 192px 60%;
      width: 250%;
      left: -50%; }
      @media screen and (min-width: 1024px) {
        .brandimages-brand:nth-child(2).active img {
          object-position: 120px 73%;
          width: 200%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(3) {
        border-right: none;
        width: 33%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(3) .brandcontent,
      .brandimages-brand:nth-child(3) .brandcontent-button--initial {
        left: 7%; } }
    .brandimages-brand .primary-button {
      font-size: 1rem;
      min-height: 32px;
      max-height: 32px;
      padding: 3px 20px;
      width: 290px; }
    .brandimages-brand > .primary-button {
      top: 30px;
      left: 20px;
      position: absolute;
      z-index: 0; }
      @media screen and (min-width: 1024px) {
        .brandimages-brand > .primary-button {
          top: auto;
          bottom: 50px;
          transform: skew(13deg);
          left: auto; } }
  .brandimages img {
    height: 100%;
    object-fit: cover;
    object-position: 25% 70%;
    position: relative;
    top: 0;
    left: -30%;
    transition: all 0.8s ease-out;
    width: 150%; }
    @media screen and (min-width: 1024px) {
      .brandimages img {
        height: calc(100vh - 293px);
        max-height: 728px;
        min-height: 400px;
        left: -20%;
        transform: skew(13deg);
        width: 160%; } }

.brandcontent {
  bottom: 80px;
  left: 20px;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transition: all 0.8s ease-out;
  top: 50px;
  width: 80%;
  z-index: 1; }
  @media screen and (min-width: 1024px) {
    .brandcontent {
      bottom: 50px;
      transform: skew(13deg);
      top: auto;
      width: 70%; } }
  @media screen and (min-width: 1280px) {
    .brandcontent {
      width: 80%; } }
  .brandcontent-button {
    bottom: 0;
    position: absolute;
    transition: all 0.8s ease-out;
    white-space: nowrap; }
    .brandcontent-button:hover {
      font-weight: 700; }
    @media screen and (min-width: 1024px) {
      .brandcontent-button {
        left: 0; } }
    .brandcontent-button--top {
      opacity: 0; }
  .brandcontent h2, .brandcontent p {
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); }
  .brandcontent h2 {
    text-transform: uppercase; }

/* Site mottos */
.mottos {
  padding-top: 40px;
  padding-bottom: 24px; }
  .mottos > div *:first-child {
    margin-top: 0; }
  .mottos > div *:last-child {
    margin-bottom: 0; }
  .mottos > div:last-child p {
    color: rgba(0, 0, 0, 0.5);
    text-align: right; }

/* Error message */
@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

/* Home configurator */
.home-configurator {
  height: calc(100vh - 293px);
  max-height: 728px;
  min-height: 400px;
  overflow: hidden; }

/* Partners */
.partners {
  background-color: #f0f0f0;
  position: relative;
  min-height: 80px; }
  .partners .partner-img {
    position: absolute; }
    .partners .partner-img img {
      opacity: 0.3;
      transition: 0.3s ease-in-out; }
      .partners .partner-img img:hover, .partners .partner-img img:hover {
        opacity: 0.8; }
  .partners .partner-img:first-child {
    left: 20.5%;
    top: 50%;
    transform: translate(-50%, -50%); }
  .partners .partner-img:last-child {
    right: 28%;
    top: 50%;
    transform: translate(50%, -50%); }

/* Find form */
.find-form {
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px; }
  .find-form .form-text {
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 16px;
    width: 100%; }
  .find-form .search-input,
  .find-form .secondary-button {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center; }
    .find-form .search-input img,
    .find-form .secondary-button img {
      margin-right: 8px; }
    .find-form .search-input input,
    .find-form .secondary-button input {
      border: 0;
      background-color: transparent;
      width: 100%; }
      .find-form .search-input input:focus,
      .find-form .secondary-button input:focus {
        outline: none; }
  .find-form .secondary-button input {
    cursor: pointer;
    text-align: center;
    transition: 0.2s ease-in-out; }
    .find-form .secondary-button input::before {
      display: block;
      content: attr(value);
      font-weight: 700;
      height: 0;
      overflow: hidden;
      visibility: hidden; }
    .find-form .secondary-button input:hover {
      font-weight: 700; }
  .find-form .search-heading > *:last-child {
    margin-bottom: 0; }
  .find-form .search-input {
    padding: 8px 16px;
    box-shadow: 0 4px 42px rgba(0, 0, 0, 0.1);
    margin-top: 24px;
    margin-bottom: 24px;
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    background-color: #ffffff;
    border: 0;
    border-radius: 100px;
    width: 387px; }
    .find-form .search-input input {
      color: rgba(0, 0, 0, 0.5); }
  .find-form .secondary-button input {
    text-transform: uppercase; }
  .find-form .form-background {
    height: 464px;
    width: 100%;
    object-fit: cover; }

/* Youtube video */
.youtube-video {
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
  height: 0;
  padding-bottom: 30%; }
  .youtube-video iframe {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%; }

/* Grid view */
.grid-field {
  background-color: #efefef;
  padding: 72px 0 144px 0; }
  .grid-field .heading {
    text-align: center;
    margin-bottom: 72px; }
  .grid-field .grid {
    align-items: center;
    justify-content: space-between; }
    .grid-field .grid .grid-form {
      padding: 8px; }
      .grid-field .grid .grid-form > div:first-child {
        background-color: #c4c4c4;
        height: 210px; }
      .grid-field .grid .grid-form > div:last-child {
        background-color: #ffffff;
        padding: 18px; }
        .grid-field .grid .grid-form > div:last-child > *:first-child {
          margin-top: 0; }
        .grid-field .grid .grid-form > div:last-child > *:last-child {
          margin-bottom: 0; }

@media only screen and (max-width: 1920px) {
  /* Partners */
  .partners .partner-img:first-child {
    left: 21.5%; }
  .partners .partner-img:last-child {
    right: 28%; }
  /* Youtube video */
  .youtube-video {
    padding-bottom: 40%; } }

@media only screen and (max-width: 1600px) {
  /* Partners */
  .partners .partner-img:first-child {
    left: 22%; }
  .partners .partner-img:last-child {
    right: 28.5%; } }

@media only screen and (max-width: 1440px) {
  /* Youtube video */
  .youtube-video {
    padding-bottom: 53%; } }

@media only screen and (max-width: 1100px) {
  /* Partners */
  .partners {
    min-height: 60px; }
    .partners img {
      max-height: 40px;
      max-width: 255px; }
    .partners .partner-img:first-child {
      left: 25%; }
    .partners .partner-img:last-child {
      right: 31%; } }

@media only screen and (max-width: 992px) {
  /* Home configurator */
  .home-configurator {
    height: calc(100vh - 91px);
    min-height: 475px;
    max-height: 800px; }
  /* Partners */
  .partners .partner-img:first-child {
    left: 21%; }
  .partners .partner-img:last-child {
    right: 26%; }
  /* Find form, YouTube video, Grid video */
  .find-form,
  .youtube-video,
  .grid-field {
    margin: 0; }
  .grid-field {
    padding: 40px 0 72px 0; }
    .grid-field .heading {
      margin-bottom: 40px; } }

@media only screen and (max-width: 991px) {
  /* Site mottos */
  .mottos {
    padding: 16px 0; }
    .mottos div > div:first-child {
      margin-bottom: 16px; }
    .mottos > div:last-child p {
      text-align: left; } }

@media only screen and (max-width: 768px) {
  /* Partners */
  .partners {
    min-height: auto; }
    .partners .wrap {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 8px 0; }
    .partners .partner-img:first-child,
    .partners .partner-img:last-child {
      position: unset;
      top: unset;
      left: unset;
      right: unset;
      transform: unset; }
  /* Home configurator */
  .home-configurator {
    height: calc(100vh - 85px); } }

@media only screen and (max-width: 650px) {
  /* Find form */
  .find-form .form-text .search-input,
  .find-form .form-text form {
    width: 100%; } }

@media only screen and (max-width: 576px) {
  /* Partners */
  .partners img {
    max-height: 40px;
    max-width: 90px; }
  .grid-field {
    padding-top: 32px; }
  /* Home configurator */
  .home-configurator {
    height: calc(100vh - 73px);
    min-height: 550px; } }

@media only screen and (max-width: 400px) {
  /* Design images*/
  .design-images .img-btn:first-child a {
    top: 16px;
    left: 8px;
    bottom: auto;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0); }
  .design-images .img-btn:last-child a {
    bottom: 16px;
    right: 8px;
    transform: translate(0, 0);
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(-50%, 0); } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

/* Brand colors */
.blueColor {
  --main-brand-color: #08315A; }

.redColor {
  --main-brand-color: #FC5F20; }

.brand-page {
  /* Heading texts */
  /* The two images */
  /* Catalog */
  /* Image with logo and button */ }
  .brand-page .heading {
    margin-top: 80px;
    margin-bottom: 80px;
    text-align: center; }
    .brand-page .heading * {
      margin-bottom: 0; }
  .brand-page .two-images {
    max-width: 100%;
    margin: 0 !important; }
    .brand-page .two-images .two-images-content {
      padding: 0;
      position: relative; }
      .brand-page .two-images .two-images-content > div {
        position: absolute;
        padding: 40px;
        bottom: 0;
        left: 0; }
        .brand-page .two-images .two-images-content > div h1 {
          font-weight: 700;
          max-width: 470px; }
        .brand-page .two-images .two-images-content > div p {
          font-size: 14px;
          line-height: 1.65;
          max-width: 380px; }
        .brand-page .two-images .two-images-content > div *:last-child {
          margin-bottom: 0; }
    .brand-page .two-images img {
      height: 800px;
      object-fit: cover;
      width: 100%; }
  .brand-page .catalog {
    background-color: #f6f6f6; }
    .brand-page .catalog .wrap {
      padding-top: 80px;
      padding-bottom: 80px; }
    .brand-page .catalog .row > div {
      text-align: center; }
    .brand-page .catalog .img-catalog {
      box-shadow: -1px 46px 42px 3px rgba(0, 0, 0, 0.15);
      border-radius: 8px; }
    .brand-page .catalog p {
      color: rgba(0, 0, 0, 0.5); }
  .brand-page .image-logo-button {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center; }
    .brand-page .image-logo-button picture {
      width: 100%; }
    .brand-page .image-logo-button picture img {
      width: 100%;
      object-fit: cover;
      height: calc(100vh - 151px);
      max-height: 800px;
      min-height: 450px; }
    .brand-page .image-logo-button .content {
      position: absolute;
      text-align: center;
      padding-top: 24px;
      padding-bottom: 24px; }
      .brand-page .image-logo-button .content img {
        margin-bottom: 24px;
        max-width: 400px; }
      .brand-page .image-logo-button .content h4 {
        margin-bottom: 24px; }
      .brand-page .image-logo-button .content a {
        margin: 0 auto 16px auto; }

/* Page Main Color Options */
.brand-page.blueColor {
  /* Heading texts */
  /* Catalog */ }
  .brand-page.blueColor .heading h1 strong {
    color: #08315a; }
  .brand-page.blueColor .catalog h1 {
    color: #08315a; }

.brand-page.redColor {
  /* Heading texts */
  /* Catalog */ }
  .brand-page.redColor .heading h1 strong {
    color: #fc5f20; }
  .brand-page.redColor .catalog h1 {
    color: #fc5f20; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .brand-page .image-logo-button {
    display: block;
    text-align: center; }
    .brand-page .image-logo-button .content {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); } }

@media only screen and (max-width: 992px) {
  /* Heading texts */
  .brand-page {
    /* The two images */
    /* Catalog */
    /* Image with logo and button */ }
    .brand-page .heading {
      margin-top: 40px;
      margin-bottom: 40px; }
    .brand-page .two-images .two-images-content > div {
      padding: 32px; }
    .brand-page .two-images img {
      height: 700px; }
    .brand-page .catalog .wrap {
      padding-top: 40px;
      padding-bottom: 40px; }
    .brand-page .catalog .row > div:first-child {
      margin-bottom: 40px; }
      .brand-page .catalog .row > div:first-child .img-catalog {
        width: 276px;
        height: 366px; }
    .brand-page .image-logo-button picture img {
      height: calc(100vh - 89px);
      max-height: 580px; }
    .brand-page .image-logo-button .content img {
      max-width: 320px; } }

@media only screen and (max-width: 768px) {
  /* Heading texts */
  .brand-page {
    /* Image with logo and button */ }
    .brand-page .image-logo-button .content img {
      max-width: 280px; } }

@media only screen and (max-width: 576px) {
  /* Heading texts */
  .brand-page {
    /* The two images */
    /* Image with logo and button */ }
    .brand-page .two-images .two-images-content > div {
      padding: 16px; }
    .brand-page .two-images img {
      height: 590px; }
    .brand-page .image-logo-button picture img {
      height: calc(100vh - 72px); }
    .brand-page .image-logo-button .content img {
      max-width: 240px; } }

@media only screen and (max-width: 400px) {
  /* Heading texts */
  .brand-page .catalog .catalog-button span {
    max-width: 60%; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

/* Content fields with text and image */
.content-fields .text-img-right,
.content-fields .text-img-left {
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 72px;
  padding-bottom: 72px; }
  .content-fields .text-img-right > div:first-child,
  .content-fields .text-img-left > div:first-child {
    margin-right: 72px; }
  .content-fields .text-img-right .field-image,
  .content-fields .text-img-left .field-image {
    flex: 1 50%;
    max-width: 50%; }
  .content-fields .text-img-right .field-text,
  .content-fields .text-img-left .field-text {
    flex: 1 40%;
    max-width: 40%; }
    .content-fields .text-img-right .field-text h1:last-of-type,
    .content-fields .text-img-right .field-text h2:last-of-type,
    .content-fields .text-img-right .field-text h3:last-of-type,
    .content-fields .text-img-right .field-text h4:last-of-type,
    .content-fields .text-img-right .field-text h5:last-of-type,
    .content-fields .text-img-right .field-text h6:last-of-type,
    .content-fields .text-img-left .field-text h1:last-of-type,
    .content-fields .text-img-left .field-text h2:last-of-type,
    .content-fields .text-img-left .field-text h3:last-of-type,
    .content-fields .text-img-left .field-text h4:last-of-type,
    .content-fields .text-img-left .field-text h5:last-of-type,
    .content-fields .text-img-left .field-text h6:last-of-type {
      margin-bottom: 32px; }
  .content-fields .text-img-right img,
  .content-fields .text-img-left img {
    width: 100%;
    height: auto;
    border-radius: 4px; }
  .content-fields .text-img-right h1,
  .content-fields .text-img-left h1 {
    text-align: center; }

.content-fields .text-img-right {
  justify-content: flex-end; }

.content-fields .text-img-left {
  justify-content: flex-start; }

@media only screen and (max-width: 1440px) {
  /* Content fields with text and image */
  .content-fields .text-img-right,
  .content-fields .text-img-left {
    justify-content: space-between; }
    .content-fields .text-img-right > div:first-child,
    .content-fields .text-img-left > div:first-child {
      margin-right: 40px; }
    .content-fields .text-img-right .field-image,
    .content-fields .text-img-right .field-text,
    .content-fields .text-img-left .field-image,
    .content-fields .text-img-left .field-text {
      flex: 1 50%;
      max-width: 50%; } }

@media only screen and (max-width: 992px) {
  /* Content fields with text and image */
  .content-fields .text-img-right,
  .content-fields .text-img-left {
    flex-flow: column;
    align-items: center; }
    .content-fields .text-img-right > div:first-child,
    .content-fields .text-img-left > div:first-child {
      margin: 0 0 32px 0; }
    .content-fields .text-img-right .field-image,
    .content-fields .text-img-right .field-text,
    .content-fields .text-img-left .field-image,
    .content-fields .text-img-left .field-text {
      flex: 1 100%;
      max-width: 100%; }
    .content-fields .text-img-right .field-text,
    .content-fields .text-img-left .field-text {
      max-width: 560px; }
      .content-fields .text-img-right .field-text h1:last-of-type,
      .content-fields .text-img-right .field-text h2:last-of-type,
      .content-fields .text-img-right .field-text h3:last-of-type,
      .content-fields .text-img-right .field-text h4:last-of-type,
      .content-fields .text-img-right .field-text h5:last-of-type,
      .content-fields .text-img-right .field-text h6:last-of-type,
      .content-fields .text-img-left .field-text h1:last-of-type,
      .content-fields .text-img-left .field-text h2:last-of-type,
      .content-fields .text-img-left .field-text h3:last-of-type,
      .content-fields .text-img-left .field-text h4:last-of-type,
      .content-fields .text-img-left .field-text h5:last-of-type,
      .content-fields .text-img-left .field-text h6:last-of-type {
        margin-bottom: 24px; }
  /* Job offers */
  .jobs-field {
    flex-flow: row wrap; }
    .jobs-field h3 {
      margin-bottom: 24px; }
    .jobs-field > div {
      flex: 1 100%; }
      .jobs-field > div:first-child {
        margin: 0 0 40px 0; }
    .jobs-field .job-candidature .candidature {
      padding-bottom: 40px;
      margin-bottom: 40px; } }

@media only screen and (max-width: 576px) {
  /* Content fields with text and image */
  .content-fields .text-img-right,
  .content-fields .text-img-left {
    padding-top: 40px;
    padding-bottom: 40px; }
  /* Job offers */
  .jobs-field {
    padding-top: 40px;
    padding-bottom: 40px; }
    .jobs-field .job-candidature .candidature {
      padding-bottom: 24px;
      margin-bottom: 24px; }
      .jobs-field .job-candidature .candidature a {
        margin-top: 24px; }
    .jobs-field .job-candidature .contact-content {
      flex-flow: column; }
      .jobs-field .job-candidature .contact-content > div:first-child {
        margin: 0 0 16px 0; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

/* Featured image with heading and text */
/* Addition of the code from the company file */
.featured-image.news .featured-image-text h1, .featured-image.news .featured-image-text h6 {
  max-width: 680px; }

.featured-image.news .featured-image-text h6 {
  margin-bottom: 16px; }

.news-sidebar {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  .news-sidebar .news-content {
    flex: 1 75%;
    margin-right: 120px; }
  .news-sidebar .sidebar {
    flex: 1 25%;
    max-width: 25%;
    min-width: 300px; }

/*** News content ***/
.news-content {
  /* Rim field */
  /* News tabs */ }
  .news-content .news-table,
  .news-content .textimage,
  .news-content .rim-field,
  .news-content .news-accordion,
  .news-content .news-tabs,
  .news-content .news-button {
    margin: 72px auto; }
  .news-content .wrap {
    margin-right: 0;
    margin-left: 0; }
  .news-content .rim-field .rim-image img {
    max-height: 900px;
    width: 100%;
    margin-bottom: 24px;
    border-radius: 8px; }
  .news-content .rim-field .rim-bar {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    background-color: #f0f0f0;
    padding: 6px 16px;
    border-radius: 8px; }
    .news-content .rim-field .rim-bar > div {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      flex-grow: 2;
      margin-right: 16px; }
      .news-content .rim-field .rim-bar > div img {
        margin-right: 16px; }
    .news-content .rim-field .rim-bar a {
      font-weight: 700; }
  .news-content .news-tabs .tabs-heading {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;
    border-bottom: 1px solid #D8D8D8;
    transition: 0.3s ease-in-out; }
    .news-content .news-tabs .tabs-heading button {
      margin-bottom: 24px;
      min-width: 135px;
      border: 2px solid #ffffff;
      transition: 0.3s ease-in-out; }
      .news-content .news-tabs .tabs-heading button.tab-color {
        color: #000000;
        background-color: #ffed00;
        border-radius: 45px;
        border: 2px solid #ffed00; }
      .news-content .news-tabs .tabs-heading button:hover {
        border: 2px solid #ffed00;
        color: #000000; }
    .news-content .news-tabs .tabs-heading button:not(:last-child) {
      margin-right: 16px; }
  .news-content .news-tabs .tabs-text {
    display: none;
    min-height: 100px; }
    .news-content .news-tabs .tabs-text.is-active {
      transition: all 1s ease;
      display: block; }
      .news-content .news-tabs .tabs-text.is-active > div {
        transition: all 1s ease;
        animation: fadeEffect 0.6s ease;
        width: 100%; }

@keyframes fadeEffect {
  from {
    opacity: 0;
    margin-left: 30px; }
  to {
    opacity: 1;
    margin-left: 0; } }

/*** Sidebar ***/
.sidebar {
  position: relative;
  margin-top: 72px;
  /* Text color */
  /* Headings */
  /* Social icons */
  /* Contacts */
  /* Banner */
  /* last articles */ }
  .sidebar p, .sidebar a {
    color: rgba(0, 0, 0, 0.5);
    margin-bottom: 0; }
  .sidebar p.heading {
    margin-bottom: 16px;
    color: #000000; }
  .sidebar .social .social-icons {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    /* Copy url button */ }
    .sidebar .social .social-icons a {
      margin-right: 24px; }
    .sidebar .social .social-icons img {
      opacity: 0.3;
      transition: 0.3s ease-in-out; }
      .sidebar .social .social-icons img:hover {
        opacity: 1; }
    .sidebar .social .social-icons input {
      height: 0;
      border: 0;
      padding: 0;
      outline: none; }
    .sidebar .social .social-icons button {
      padding: 0;
      position: relative; }
      .sidebar .social .social-icons button .tooltipText {
        visibility: hidden;
        width: 80px;
        background-color: #A4A4A4;
        color: #fff;
        text-align: center;
        border-radius: 3px;
        padding: 3px;
        position: absolute;
        z-index: 1;
        bottom: 130%;
        left: 50%;
        transform: translate(-50%, 0);
        opacity: 0;
        transition: opacity 0.3s;
        font-size: 10px; }
      .sidebar .social .social-icons button .tooltipText::after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #A4A4A4 transparent transparent transparent; }
      .sidebar .social .social-icons button:hover .tooltipText {
        visibility: visible;
        opacity: 1; }
  .sidebar .contact .email {
    margin-bottom: 24px; }
    .sidebar .contact .email a {
      text-transform: uppercase; }
  .sidebar .banner img {
    max-width: 100%;
    border-radius: 4px; }
  .sidebar .social-icons,
  .sidebar .contact,
  .sidebar .banner {
    margin-bottom: 32px; }
  .sidebar .last-articles .box-links {
    margin-bottom: 24px; }
    .sidebar .last-articles .box-links a {
      background-color: #f0f0f0;
      padding: 0;
      width: 100%;
      min-height: 66px; }
      .sidebar .last-articles .box-links a:hover {
        background-color: #D8D8D8; }
  .sidebar .last-articles .article-text, .sidebar .last-articles span {
    margin: 6px 16px 6px 0; }
  .sidebar .last-articles .article-text {
    flex-grow: 2;
    margin-left: 16px; }
    .sidebar .last-articles .article-text .title {
      font-size: 14px; }
    .sidebar .last-articles .article-text p {
      color: #000000;
      font-weight: 400;
      white-space: nowrap;
      width: 160px;
      overflow: hidden;
      text-overflow: ellipsis; }
  .sidebar .last-articles .article-img {
    background-color: rgba(0, 0, 0, 0.5);
    width: 66px;
    min-width: 66px;
    display: flex;
    align-items: stretch; }
    .sidebar .last-articles .article-img img {
      width: 100%;
      height: 66px;
      object-fit: cover; }

/* Related articles */
.related-articles {
  flex: 1 100%;
  border-top: 1px solid #d8d8d8;
  padding: 72px 0 144px 0; }
  .related-articles h1 {
    text-align: center;
    margin: 0 0 40px 0; }
  .related-articles > div {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between; }
    .related-articles > div a {
      flex: 1 33%;
      min-width: 25%; }
      .related-articles > div a:not(:last-child) {
        margin-right: 16px; }
      .related-articles > div a > div {
        padding: 32px 16px 0 16px; }
  .related-articles img {
    width: 100%;
    height: 365px;
    object-fit: cover;
    border-radius: 6px; }

@media only screen and (max-width: 1300px) {
  .news-sidebar {
    /* last articles */ }
    .news-sidebar .sidebar .last-articles .article-text, .news-sidebar .sidebar .last-articles span {
      margin: 6px; } }

@media only screen and (max-width: 1200px) {
  .news-sidebar .news-content {
    margin-right: 40px; } }

@media only screen and (max-width: 992px) {
  /*** News content ***/
  .news-content {
    /* Text and image */ }
    .news-content .news-table,
    .news-content .news-text-image,
    .news-content .rim-field,
    .news-content .news-accordion,
    .news-content .news-tabs,
    .news-content .news-button {
      margin: 40px auto; }
    .news-content .news-text-image {
      flex-flow: row wrap; }
      .news-content .news-text-image .text,
      .news-content .news-text-image .image {
        flex: 1 100%; }
      .news-content .news-text-image > div:first-child {
        margin: 0 0 40px 0; }
  /*** Sidebar ***/
  .news-sidebar {
    display: flex;
    flex-flow: row wrap; }
    .news-sidebar .news-content {
      flex: 1 100%;
      margin: 0; }
    .news-sidebar .sidebar {
      flex: 1 100%;
      max-width: 100%;
      margin: 0 0 40px 0;
      display: none; }
  /* Related articles */
  .related-articles {
    padding: 40px 0 72px 0; }
    .related-articles > div {
      flex-flow: row wrap; }
      .related-articles > div a {
        flex: 1 100%;
        max-width: 60%;
        margin: 0 auto; }
        .related-articles > div a:not(:last-child) {
          margin: 0 auto 16px auto; }
        .related-articles > div a > div {
          padding: 16px 0 0 0; }
    .related-articles img {
      height: 256px; } }

@media only screen and (max-width: 768px) {
  /*** News content ***/
  .news-content {
    /* Rim field */
    /* News tabs */ }
    .news-content .rim-field .rim-bar {
      flex-flow: row wrap;
      padding: 16px; }
      .news-content .rim-field .rim-bar > div {
        margin: 0 auto 16px auto;
        justify-content: center; }
      .news-content .rim-field .rim-bar a {
        flex: 1 100%;
        max-width: 100%; }
    .news-content .news-tabs .tabs-heading button {
      min-width: 97px; } }

@media only screen and (max-width: 576px) {
  /*** News content ***/
  .news-content {
    /* News tabs */ }
    .news-content .news-table,
    .news-content .news-text-image,
    .news-content .rim-field,
    .news-content .news-accordion,
    .news-content .news-tabs,
    .news-content .sidebar,
    .news-content .news-button {
      margin: 32px auto; }
    .news-content .news-text-image .image img {
      max-height: 350px; }
    .news-content .news-tabs .tabs-heading button {
      margin-bottom: 16px; }
    .news-content .news-tabs .tabs-heading button:not(:last-child) {
      margin-right: 8px; }
  /* Related articles */
  .related-articles {
    padding-top: 32px; }
    .related-articles > div a {
      max-width: 100%; }
    .related-articles img {
      height: 256px; } }

.featured-image.certificates img {
  object-position: 50% 65%; }

.featured-image.certificates .featured-image-heading span {
  max-width: 650px;
  margin: 0 auto; }

.certificates-content {
  position: relative;
  min-height: 100vh;
  animation: 0.7s fadeIn; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

.contact-page {
  padding-top: 72px;
  /* Text fields */
  /* Contact form */
  /* Contacts info and Map */ }
  .contact-page h1 {
    text-align: center;
    margin-bottom: 40px; }
  .contact-page .text-columns,
  .contact-page .form-columns {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px; }
    .contact-page .text-columns > div,
    .contact-page .form-columns > div {
      flex: 1 40%; }
      .contact-page .text-columns > div:last-child,
      .contact-page .form-columns > div:last-child {
        margin-left: 60px; }
  .contact-page .text-columns {
    margin-bottom: 40px; }
    .contact-page .text-columns > div p {
      text-align: justify;
      line-height: 1.65;
      font-size: 15px; }
      .contact-page .text-columns > div p:last-child {
        margin-bottom: 0; }
  .contact-page .contact-form {
    padding-bottom: 72px; }
    .contact-page .contact-form .form-columns,
    .contact-page .contact-form .form-columns > div > div:not(:last-child) {
      margin-bottom: 12px; }
    .contact-page .contact-form label:not(.accept) {
      color: #a4a4a4;
      text-transform: uppercase;
      padding: 0 16px;
      font-size: 13px; }
    .contact-page .contact-form label.error {
      color: #fc5f20; }
    .contact-page .contact-form .inputContainer {
      position: relative; }
      .contact-page .contact-form .inputContainer .errorContainer {
        position: absolute;
        top: 30%;
        right: 20px; }
        .contact-page .contact-form .inputContainer .errorContainer label {
          color: #fc5f20; }
    .contact-page .contact-form input:focus {
      outline: none !important;
      border-color: #ffed00 !important;
      border-style: solid !important; }
    .contact-page .contact-form input:not(.submit-btn):not(.hidden-checkbox),
    .contact-page .contact-form textarea {
      border: 2px solid #d8d8d8;
      border-radius: 8px;
      width: 100%;
      margin-top: 6px;
      padding: 8px 16px; }
      .contact-page .contact-form input:not(.submit-btn):not(.hidden-checkbox):focus, .contact-page .contact-form input:not(.submit-btn):not(.hidden-checkbox):focus-visible, .contact-page .contact-form input:not(.submit-btn):not(.hidden-checkbox):hover,
      .contact-page .contact-form textarea:focus,
      .contact-page .contact-form textarea:focus-visible,
      .contact-page .contact-form textarea:hover {
        outline: none !important;
        border-color: #ffed00 !important;
        border-style: solid !important; }
      .contact-page .contact-form input:not(.submit-btn):not(.hidden-checkbox).error,
      .contact-page .contact-form textarea.error {
        border-color: #fc5f20;
        background-color: rgba(252, 95, 32, 0.2); }
    .contact-page .contact-form .accept-submit {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 0; }
      .contact-page .contact-form .accept-submit .accept {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 16px 0 0;
        position: relative; }
        .contact-page .contact-form .accept-submit .accept .hidden-checkbox {
          opacity: 0; }
        .contact-page .contact-form .accept-submit .accept .custom-checkbox {
          position: absolute;
          top: 2px;
          left: 0;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid #ffed00;
          cursor: pointer; }
          .contact-page .contact-form .accept-submit .accept .custom-checkbox::after {
            position: absolute;
            content: "";
            left: 2px;
            top: 2px;
            height: 8px;
            width: 8px;
            border-radius: 50%; }
        .contact-page .contact-form .accept-submit .accept .hidden-checkbox:checked ~ .custom-checkbox::after {
          background-color: #ffed00; }
        .contact-page .contact-form .accept-submit .accept .text {
          font-size: 12px;
          margin-left: 6px; }
          .contact-page .contact-form .accept-submit .accept .text a {
            font-weight: 700;
            color: #000000;
            text-decoration: underline; }
            .contact-page .contact-form .accept-submit .accept .text a:hover {
              text-decoration: none; }
      .contact-page .contact-form .accept-submit .submit-btn {
        font-weight: 700; }
  .contact-page .contacts-map {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;
    background-color: #ffed00; }
    .contact-page .contacts-map > div:first-of-type {
      flex: 1 20%;
      padding: 40px;
      margin: 0 auto;
      color: #000000;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: center; }
      .contact-page .contacts-map > div:first-of-type .logo {
        margin-bottom: 24px; }
      .contact-page .contacts-map > div:first-of-type .contact-info {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: 25px; }
        .contact-page .contacts-map > div:first-of-type .contact-info:not(:last-child) {
          margin-bottom: 16px; }
        .contact-page .contacts-map > div:first-of-type .contact-info img {
          margin-right: 32px; }
        .contact-page .contacts-map > div:first-of-type .contact-info p {
          margin-bottom: 0; }
        .contact-page .contacts-map > div:first-of-type .contact-info a {
          color: #000000; }
          .contact-page .contacts-map > div:first-of-type .contact-info a:hover {
            text-decoration: underline; }
    .contact-page .contacts-map > div:last-of-type {
      flex: 1 80%;
      width: 80%; }

@media only screen and (max-width: 992px) {
  .contact-page {
    padding-top: 40px;
    /* Contact form */
    /* Contacts info and Map */ }
    .contact-page .contact-form {
      padding-bottom: 40px; }
    .contact-page .contacts-map > div:first-of-type {
      padding: 24px; } }

@media only screen and (max-width: 768px) {
  .contact-page {
    /* Text fields */
    /* Contact form */
    /* Contacts info and Map */ }
    .contact-page h1 {
      margin-bottom: 40px; }
    .contact-page .text-columns {
      flex-flow: column;
      margin-bottom: 40px; }
      .contact-page .text-columns > div {
        flex: 1 100%;
        width: 100%; }
        .contact-page .text-columns > div:last-child {
          margin: 8px 0 0 0; }
    .contact-page .form-columns > div:last-child {
      margin-left: 24px; }
    .contact-page .contacts-map {
      flex-flow: column;
      align-items: center; }
      .contact-page .contacts-map > div:first-of-type,
      .contact-page .contacts-map > div:last-of-type {
        flex: 1 100%; }
      .contact-page .contacts-map > div:last-of-type {
        width: 100%; } }

@media only screen and (max-width: 576px) {
  .contact-page {
    /* Text fields */
    /* Contact form */ }
    .contact-page h1,
    .contact-page .text-columns,
    .contact-page .form-columns {
      margin-bottom: 32px; }
    .contact-page .form-columns {
      flex-flow: column; }
      .contact-page .form-columns > div {
        flex: 1 100%;
        width: 100%; }
        .contact-page .form-columns > div:last-child {
          margin: 12px 0 0 0; }
        .contact-page .form-columns > div > div:not(:last-child) {
          margin: 0 0 12px 0; }
    .contact-page .contact-form .accept-submit {
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 32px;
      margin-bottom: 0; }
      .contact-page .contact-form .accept-submit label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 16px; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

.not-found-page,
.error-page {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  animation: 2s fadeIn;
  min-height: 400px;
  max-height: 800px;
  height: calc(100vh - 151px);
  /* For IE */ }
  .not-found-page img.background,
  .error-page img.background {
    width: 100%;
    height: calc(100vh - 151px);
    min-height: 400px;
    max-height: 800px;
    object-fit: cover; }
  .not-found-page .content,
  .error-page .content {
    text-align: center;
    position: absolute;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 24px;
    margin: 24px; }
    .not-found-page .content img,
    .error-page .content img {
      border-radius: 8px; }
    .not-found-page .content > div h2,
    .not-found-page .content > div p,
    .error-page .content > div h2,
    .error-page .content > div p {
      font-weight: 700;
      color: #08315a; }
    .not-found-page .content > div p,
    .error-page .content > div p {
      margin-bottom: 0; }
  .not-found-page .content::-ms-expand,
  .error-page .content::-ms-expand {
    top: 16px; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .not-found-page,
  .error-page {
    /* For IE */ }
    .not-found-page .content,
    .error-page .content {
      top: 16px;
      transform: translate(-50%, 0); } }

@media only screen and (max-width: 992px) {
  .not-found-page,
  .error-page {
    height: calc(100vh - 92px); }
    .not-found-page img.background,
    .error-page img.background {
      height: calc(100vh - 92px); } }

@media only screen and (max-width: 576px) {
  .not-found-page,
  .error-page {
    height: calc(100vh - 77px); }
    .not-found-page img.background,
    .error-page img.background {
      height: calc(100vh - 77px); }
    .not-found-page .content img,
    .error-page .content img {
      height: 80px;
      width: 225px; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

.info-featured-image {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center; }
  .info-featured-image img {
    width: 100%;
    object-fit: cover;
    height: 200px; }
  .info-featured-image h1 {
    position: absolute;
    margin: 0;
    font-weight: 700;
    text-align: center; }

.info-content {
  padding-top: 72px;
  padding-bottom: 72px; }

@media only screen and (max-width: 576px) {
  .info-content {
    padding-top: 40px;
    padding-bottom: 40px; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

.search-page {
  padding: 0 0 144px 0; }
  .search-page .search-featured-image .content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center; }
  .search-page .search-results {
    padding-top: 72px; }
    .search-page .search-results h5 {
      margin-bottom: 40px; }
    .search-page .search-results ul {
      list-style-type: none;
      padding-left: 0; }
    .search-page .search-results li {
      margin-bottom: 15px;
      border-bottom: 1px solid #ddd;
      padding-bottom: 15px; }
      .search-page .search-results li:last-child {
        border-bottom: 0; }

.empty-search {
  height: calc(100vh - 151px);
  min-height: 400px;
  max-height: 800px; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.empty-search,
.search-featured-image {
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  animation: 2s fadeIn;
  /* For IE */ }
  .empty-search img.background,
  .search-featured-image img.background {
    width: 100%;
    height: calc(100vh - 151px);
    min-height: 400px;
    max-height: 800px;
    object-fit: cover; }
  .empty-search .content,
  .search-featured-image .content {
    text-align: center;
    position: absolute;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 24px;
    margin: 24px; }
    .empty-search .content img,
    .search-featured-image .content img {
      border-radius: 8px; }
    .empty-search .content > div h2,
    .empty-search .content > div p,
    .search-featured-image .content > div h2,
    .search-featured-image .content > div p {
      font-weight: 700;
      color: #08315a; }
    .empty-search .content > div p,
    .search-featured-image .content > div p {
      margin-bottom: 0; }
    .empty-search .content > div span,
    .search-featured-image .content > div span {
      font-size: 18px; }
  .empty-search .content::-ms-expand,
  .search-featured-image .content::-ms-expand {
    top: 16px; }

.search-featured-image img.background {
  height: 200px;
  min-height: 200px; }

.search-highlight {
  font-weight: bold; }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .empty-search {
    /* For IE */ }
    .empty-search .content {
      top: 16px;
      transform: translate(-50%, 0); } }

@media only screen and (max-width: 992px) {
  .search-page {
    padding: 0 0 72px 0; }
    .search-page .search-results {
      padding-top: 40px; }
  .empty-search {
    height: calc(100vh - 92px); }
    .empty-search img.background {
      height: calc(100vh - 92px); }
  .search-featured-image img.background {
    height: 150px;
    min-height: 150px; } }

@media only screen and (max-width: 576px) {
  .search-page .search-featured-image .content {
    padding: 16px; }
    .search-page .search-featured-image .content img:first-of-type {
      width: 35px;
      height: 35px; }
    .search-page .search-featured-image .content h2 {
      margin-right: 16px; }
    .search-page .search-featured-image .content img:last-of-type {
      width: 30px;
      height: 30px; }
  .empty-search {
    height: calc(100vh - 77px); }
    .empty-search img.background {
      height: calc(100vh - 77px); }
    .empty-search .content img {
      height: 80px;
      width: 225px; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

.news-overview {
  padding: 72px 0 144px 0; }
  .news-overview .news-overview-wrapper {
    background-color: #f6f6f6; }

@media only screen and (max-width: 992px) {
  .news-overview {
    padding: 40px 0 72px 0; } }

.configurator-page {
  min-height: calc(100vh - 153px); }

@media only screen and (max-width: 992px) {
  .configurator-page {
    min-height: calc(100vh - 92px); } }

@media only screen and (max-width: 576px) {
  .configurator-page {
    min-height: auto; } }

#branches-app {
  max-width: 1920px;
  margin: 0 auto;
  height: calc(100vh - 153px); }

@media only screen and (max-width: 992px) {
  #branches-app {
    height: calc(100vh - 92px); } }

@media only screen and (max-width: 576px) {
  #branches-app {
    height: auto; } }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

#configurator-widget {
  height: 100%; }

.laptop,
.tablet,
.mobile {
  display: none !important; }

@media only screen and (max-width: 1200px) {
  .laptop {
    display: flex !important; } }

@media only screen and (max-width: 992px) {
  .desktop {
    display: none !important; }
  .tablet {
    display: flex !important; } }

@media only screen and (max-width: 768px) {
  .tablet {
    display: none !important; }
  .mobile {
    display: flex !important; } }

.featured-image.rimsOverview img {
  object-position: 50% 65%; }

/* Heading texts */
.heading.wrap {
  margin-top: 80px;
  margin-bottom: 80px;
  text-align: center; }

#rimsOverview-widget {
  height: 100%; }

/* Components
--------------------------------------------- */
.brandimages {
  overflow: hidden;
  position: relative;
  transition: all 0.7s ease-in;
  opacity: 1;
  animation: 2s fadeIn;
  max-width: 2560px; }
  @media screen and (min-width: 1024px) {
    .brandimages {
      height: calc(100vh - 293px);
      max-height: 728px;
      min-height: 400px;
      overflow: hidden; } }
  .brandimages-wrapper {
    display: flex;
    flex-direction: column; }
    @media screen and (min-width: 1024px) {
      .brandimages-wrapper {
        flex-direction: row;
        left: -6%;
        position: absolute;
        width: 112%; } }
    @media screen and (min-width: 1440px) {
      .brandimages-wrapper {
        width: 110%;
        left: -5%; } }
  .brandimages-brand {
    background-color: #000;
    border-bottom: 3px solid white;
    height: calc(24vh);
    overflow: hidden;
    position: relative;
    transition: 0.8s ease-out;
    width: 100%; }
    @media screen and (min-width: 1024px) {
      .brandimages-brand {
        border-right: 3px solid white;
        flex: 33.3333%;
        height: 100%;
        transform: skew(-13deg);
        width: 33.33333%; } }
    @media screen and (max-width: 1023px) {
      .brandimages-brand.active {
        height: 34vh; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand.active {
        flex: 45% !important; } }
    .brandimages-brand.active .brandcontent {
      opacity: 1;
      height: 255px;
      top: 30px; }
      @media screen and (min-width: 1024px) {
        .brandimages-brand.active .brandcontent {
          height: 175px;
          top: auto;
          bottom: 50px; } }
      .brandimages-brand.active .brandcontent-button {
        opacity: 1; }
        .brandimages-brand.active .brandcontent-button--initial {
          opacity: 0; }
        .brandimages-brand.active .brandcontent-button--top {
          bottom: 50px;
          opacity: 1; }
          @media screen and (min-width: 1024px) {
            .brandimages-brand.active .brandcontent-button--top {
              bottom: 0; } }
        .brandimages-brand.active .brandcontent-button--bottom {
          bottom: 0; }
          @media screen and (min-width: 1024px) {
            .brandimages-brand.active .brandcontent-button--bottom {
              bottom: 44px; } }
    .brandimages-brand.active img {
      opacity: 0.7; }
      @media screen and (max-width: 1023px) {
        .brandimages-brand.active img {
          top: -35%;
          height: 150%;
          width: 150%; } }
    @media screen and (max-width: 1023px) {
      .brandimages-brand.small {
        height: 20vh; } }
    .brandimages-brand.small .brandcontent {
      opacity: 0; }
      @media screen and (max-width: 1023px) {
        .brandimages-brand.small .brandcontent {
          top: 50px; } }
      .brandimages-brand.small .brandcontent-button--initial {
        opacity: 1; }
    .brandimages-brand.small img {
      opacity: 1; }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(1) {
        width: 40%; } }
    .brandimages-brand:nth-child(1).active img {
      object-position: 35px 63%;
      width: 215%;
      left: -50%; }
      @media screen and (min-width: 1024px) {
        .brandimages-brand:nth-child(1).active img {
          object-position: 35px 73%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(1) .brandcontent {
        left: 33%;
        width: 65%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(1) .brandcontent-button--initial {
        left: 33%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(2) .brandcontent,
      .brandimages-brand:nth-child(2) .brandcontent-button--initial {
        left: 7%; } }
    @media screen and (max-width: 1023px) {
      .brandimages-brand:nth-child(2) img {
        object-position: 113px 65%;
        width: 180%; } }
    .brandimages-brand:nth-child(2).active img {
      object-position: 192px 60%;
      width: 250%;
      left: -50%; }
      @media screen and (min-width: 1024px) {
        .brandimages-brand:nth-child(2).active img {
          object-position: 120px 73%;
          width: 200%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(3) {
        border-right: none;
        width: 33%; } }
    @media screen and (min-width: 1024px) {
      .brandimages-brand:nth-child(3) .brandcontent,
      .brandimages-brand:nth-child(3) .brandcontent-button--initial {
        left: 7%; } }
    .brandimages-brand .primary-button {
      font-size: 1rem;
      min-height: 32px;
      max-height: 32px;
      padding: 3px 20px;
      width: 290px; }
    .brandimages-brand > .primary-button {
      top: 30px;
      left: 20px;
      position: absolute;
      z-index: 0; }
      @media screen and (min-width: 1024px) {
        .brandimages-brand > .primary-button {
          top: auto;
          bottom: 50px;
          transform: skew(13deg);
          left: auto; } }
  .brandimages img {
    height: 100%;
    object-fit: cover;
    object-position: 25% 70%;
    position: relative;
    top: 0;
    left: -30%;
    transition: all 0.8s ease-out;
    width: 150%; }
    @media screen and (min-width: 1024px) {
      .brandimages img {
        height: calc(100vh - 293px);
        max-height: 728px;
        min-height: 400px;
        left: -20%;
        transform: skew(13deg);
        width: 160%; } }

.brandcontent {
  bottom: 80px;
  left: 20px;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  transition: all 0.8s ease-out;
  top: 50px;
  width: 80%;
  z-index: 1; }
  @media screen and (min-width: 1024px) {
    .brandcontent {
      bottom: 50px;
      transform: skew(13deg);
      top: auto;
      width: 70%; } }
  @media screen and (min-width: 1280px) {
    .brandcontent {
      width: 80%; } }
  .brandcontent-button {
    bottom: 0;
    position: absolute;
    transition: all 0.8s ease-out;
    white-space: nowrap; }
    .brandcontent-button:hover {
      font-weight: 700; }
    @media screen and (min-width: 1024px) {
      .brandcontent-button {
        left: 0; } }
    .brandcontent-button--top {
      opacity: 0; }
  .brandcontent h2, .brandcontent p {
    color: white;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); }
  .brandcontent h2 {
    text-transform: uppercase; }

.element {
  margin-top: 72px;
  margin-bottom: 72px; }
  @media only screen and (max-width: 576px) {
    .element {
      margin-top: 32px;
      margin-bottom: 32px; } }
  @media only screen and (max-width: 992px) {
    .element {
      margin-top: 40px;
      margin-bottom: 40px; } }
  .element:last-child {
    margin-bottom: 0; }

.colored-odd:nth-of-type(odd) {
  background-color: #f6f6f6; }

.colored-even:nth-of-type(even) {
  background-color: #f6f6f6; }

.textimage {
  padding-top: 40px;
  padding-bottom: 40px; }
  @media screen and (min-width: 577px) {
    .textimage {
      padding-top: 72px;
      padding-bottom: 72px; } }
  .textimage-wrapper {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between; }
    @media screen and (min-width: 992px) {
      .textimage-wrapper {
        flex-flow: row nowrap;
        justify-content: space-between; } }
    .textimage-wrapper__left .textimage-image {
      margin-bottom: 40px; }
      @media only screen and (min-width: 992px) {
        .textimage-wrapper__left .textimage-image {
          margin-right: 72px;
          margin-bottom: 0; } }
    .textimage-wrapper__left .textimage-text {
      margin-bottom: 0; }
    .textimage-wrapper__right {
      flex-direction: column-reverse; }
      @media only screen and (min-width: 992px) {
        .textimage-wrapper__right {
          flex-direction: row-reverse; } }
      .textimage-wrapper__right .textimage-image {
        margin-top: 0;
        margin-bottom: 0; }
      .textimage-wrapper__right .textimage-text {
        margin-bottom: 40px; }
        @media only screen and (min-width: 992px) {
          .textimage-wrapper__right .textimage-text {
            margin-right: 72px;
            margin-bottom: 0; } }
  .textimage-image {
    flex: 1 100%;
    max-width: 100%; }
    @media only screen and (min-width: 992px) {
      .textimage-image {
        flex: 1 50%;
        max-width: 50%; } }
  .textimage-text {
    flex: 1 100%;
    max-width: 100%; }
    @media only screen and (min-width: 992px) {
      .textimage-text {
        flex: 1 50%;
        max-width: 50%; } }
    .textimage-text h2 {
      text-align: center;
      font-weight: 700; }
      .textimage-text h2 span {
        display: block;
        font-weight: 400; }
    .textimage-text h1:last-of-type,
    .textimage-text h2:last-of-type,
    .textimage-text h3:last-of-type,
    .textimage-text h4:last-of-type,
    .textimage-text h5:last-of-type,
    .textimage-text h6:last-of-type {
      margin-bottom: 32px; }
  .textimage img {
    width: 100%;
    height: auto;
    border-radius: 4px; }
  .textimage h2 {
    text-align: center; }

.gallery-small {
  display: flex;
  flex-flow: row nowrap; }
  @media only screen and (max-width: 992px) {
    .gallery-small {
      border-radius: 4px;
      overflow: hidden; }
      .gallery-small .space-column,
      .gallery-small .space {
        display: none; } }
  .gallery-small > div:nth-child(1) {
    flex: 1 50%; }
  .gallery-small > div:nth-child(2) {
    flex: 16px; }
  .gallery-small > div:nth-child(3) {
    flex: 1 50%;
    display: flex;
    flex-flow: column; }
    .gallery-small > div:nth-child(3) > div:nth-child(1) {
      flex: 1 50%; }
    .gallery-small > div:nth-child(3) > div:nth-child(2) {
      height: 16px; }
    .gallery-small > div:nth-child(3) > div:nth-child(3) {
      flex: 1 50%; }
    .gallery-small > div:nth-child(3) > div:nth-child(4) {
      display: none; }
  .gallery-small img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    object-fit: cover; }
    @media only screen and (max-width: 992px) {
      .gallery-small img {
        border-radius: 0; } }

blockquote {
  text-align: center;
  max-width: 555px;
  margin-right: auto;
  margin-left: auto; }

.accordion {
  border-top: 1px solid #dedede; }
  .accordion > div {
    transition: 0.5s ease-in-out; }
  .accordion > div:hover {
    background-color: #f6f6f6; }
    .accordion > div:hover .yellow-circle-arrow {
      background-color: #000000; }
      .accordion > div:hover .yellow-circle-arrow span span {
        background-color: #ffed00; }
  .accordion-wrapper {
    border-bottom: 1px solid #dedede; }
  .accordion-heading {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer; }
    .accordion-heading.is-active {
      border-bottom: none;
      background-color: #f6f6f6; }
  .accordion-content {
    padding: 16px;
    display: flex;
    flex-direction: column; }
    @media only screen and (min-width: 992px) {
      .accordion-content {
        flex-direction: row; } }
    .accordion-content.is-active {
      background-color: #f6f6f6; }
    .accordion-content-text {
      flex: 0 0 70%; }
    .accordion-content-image {
      flex: 0 0 30%;
      max-width: 400px; }
      @media only screen and (min-width: 992px) {
        .accordion-content-image {
          padding-left: 32px; } }
      .accordion-content-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 300px; }
        .accordion-content-image img:first-child {
          margin-bottom: 8px; }

.video-heading {
  margin-bottom: 32px; }

.video-content iframe {
  border: 0;
  width: 100%;
  height: calc(100vw/1.77); }

.hintbox {
  padding-top: 40px;
  padding-bottom: 40px; }
  @media screen and (min-width: 1024px) {
    .hintbox {
      padding-top: 72px;
      padding-bottom: 72px; } }
  .hintbox:not(.wrap-full) {
    padding-left: 24px;
    padding-right: 24px; }
    @media screen and (min-width: 1024px) {
      .hintbox:not(.wrap-full) {
        padding-left: 72px;
        padding-right: 72px; } }
  .hintbox.yellow {
    background-color: #ffed00; }
  .hintbox.black {
    background-color: #000000; }
    .hintbox.black h2, .hintbox.black p {
      color: white; }
  .hintbox-inner {
    display: flex;
    flex-direction: column-reverse; }
    @media screen and (min-width: 1024px) {
      .hintbox-inner {
        flex-direction: row; } }
  @media screen and (min-width: 1024px) {
    .hintbox-content {
      max-width: 50%;
      margin-right: 50px; } }
  .hintbox-icon {
    display: block;
    height: 118px;
    margin-bottom: 32px;
    width: 129px; }
  .hintbox-headline {
    font-weight: 700;
    margin-bottom: 32px; }
  .hintbox-cta {
    margin-top: 16px; }

.teaser {
  background-size: cover;
  background-position: center;
  padding-top: 32px;
  padding-bottom: 32px; }
  @media screen and (min-width: 768px) {
    .teaser {
      padding-top: 40px;
      padding-bottom: 40px; } }
  @media screen and (min-width: 1024px) {
    .teaser {
      padding-top: 144px;
      padding-bottom: 144px; } }
  .teaser-content {
    margin-bottom: 2rem; }
    @media screen and (min-width: 768px) {
      .teaser-content {
        max-width: 370px; } }
    @media screen and (min-width: 1024px) {
      .teaser-content {
        max-width: 500px; } }
    .teaser-content h1, .teaser-content h2, .teaser-content h3 {
      text-align: center; }
    .teaser-content.black h1, .teaser-content.black h2, .teaser-content.black p {
      color: #000000; }
    .teaser-content.white h1, .teaser-content.white h2, .teaser-content.white p {
      color: #ffffff; }
    .teaser-content.yellow h1, .teaser-content.yellow h2, .teaser-content.yellow p {
      color: #ffed00; }
    .teaser-content.darkblue h1, .teaser-content.darkblue h2, .teaser-content.darkblue p {
      color: #08315a; }
    .teaser-content.orange h1, .teaser-content.orange h2, .teaser-content.orange p {
      color: #fc5f20; }
  .teaser-headline {
    font-weight: 700;
    margin-bottom: 1rem; }
  .teaser-btn {
    border-radius: 25px;
    border: 2px solid;
    font-weight: 700;
    padding: 1px 15px;
    text-transform: uppercase; }
    .teaser-btn__primary {
      background-color: #ffed00;
      border-color: #ffed00;
      color: #000000; }
      .teaser-btn__primary:hover {
        background-color: transparent;
        border-color: #ffed00;
        color: #ffed00; }
    .teaser-btn__secondary {
      background-color: #000000;
      border-color: #000000;
      color: #000000; }
      .teaser-btn__secondary:hover {
        background-color: transparent;
        border-color: #000000;
        color: #000000; }
    .teaser-btn__negative {
      background-color: #ffffff;
      border-color: #ffffff;
      color: #000000; }
      .teaser-btn__negative:hover {
        background-color: transparent;
        border-color: #000000;
        color: #ffffff; }
    .teaser-btn__2drv {
      background-color: #08315a;
      border-color: #08315a;
      color: #ffffff; }
      .teaser-btn__2drv:hover {
        background-color: transparent;
        border-color: #08315a;
        color: #08315a; }
    .teaser-btn__axxion {
      background-color: #fc5f20;
      border-color: #fc5f20;
      color: #ffffff; }
      .teaser-btn__axxion:hover {
        background-color: transparent;
        border-color: #fc5f20;
        color: #fc5f20; }

.featured-image {
  position: relative; }
  .featured-image img, .featured-image-media {
    width: 100%;
    object-fit: cover;
    height: calc(100vh - 240px);
    max-height: 800px;
    min-height: 400px;
    overflow: hidden; }
    @media only screen and (max-width: 576px) {
      .featured-image img, .featured-image-media {
        height: calc(100vh - 135px); } }
    @media only screen and (max-width: 992px) {
      .featured-image img, .featured-image-media {
        height: calc(100vh - 180px);
        max-height: 580px; } }
  .featured-image .featured-content {
    bottom: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    overflow: hidden; }
    @media screen and (min-width: 996px) {
      .featured-image .featured-content.hasvideo {
        top: 50%;
        transform: translateY(-50%); } }
    @media screen and (min-width: 576px) {
      .featured-image .featured-content {
        bottom: 32px; } }
    @media screen and (min-width: 996px) {
      .featured-image .featured-content {
        top: 72px;
        bottom: auto; } }
    .featured-image .featured-content h2 {
      font-size: 64px;
      line-height: 1; }
      @media screen and (min-width: 996px) {
        .featured-image .featured-content h2 {
          font-size: 100px; } }
      @media screen and (min-width: 1280px) {
        .featured-image .featured-content h2 {
          font-size: 120px; } }
    .featured-image .featured-content-inner.center .featured-image-heading {
      max-width: 100%;
      margin-right: auto;
      margin-left: auto; }
      @media screen and (min-width: 996px) {
        .featured-image .featured-content-inner.center .featured-image-heading {
          max-width: 70%; } }
    .featured-image .featured-content-inner.center h2 {
      text-align: center; }
  @media screen and (min-width: 996px) {
    .featured-image-heading {
      text-align: left;
      max-width: 800px; } }
  .featured-image-text {
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    height: 100%; }
    @media only screen and (max-width: 576px) {
      .featured-image-text {
        bottom: 16px; } }
    @media only screen and (max-width: 992px) {
      .featured-image-text {
        bottom: 32px; } }
    @media only screen and (max-width: 1440px) {
      .featured-image-text {
        left: 0;
        transform: translate(0);
        -webkit-transform: translate(0);
        -ms-transform: translate(0); } }
    .featured-image-text p {
      max-width: 325px;
      line-height: 1.65;
      margin-bottom: 0; }

.jobs {
  background-color: #f6f6f6;
  margin-top: 0;
  margin-bottom: 0; }
  .jobs-field {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 72px;
    padding-bottom: 72px; }
    .jobs-field > div {
      flex: 1 50%; }
      .jobs-field > div:first-child {
        margin-right: 72px; }
    .jobs-field h3 {
      margin-bottom: 32px; }
    .jobs-field a {
      text-transform: uppercase;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between; }
    .jobs-field .job-candidature h3 {
      color: #a4a4a4; }
    .jobs-field .job-candidature .candidature {
      padding-bottom: 56px;
      margin-bottom: 56px;
      border-bottom: 1px solid #d8d8d8; }
      .jobs-field .job-candidature .candidature a {
        margin-top: 32px;
        font-weight: 600;
        justify-content: flex-start; }
        .jobs-field .job-candidature .candidature a svg {
          margin-left: 16px;
          stroke: #000000;
          transition: 0.4s ease-in-out; }
    .jobs-field .job-candidature .contact-content {
      display: flex;
      flex-flow: row wrap; }
      .jobs-field .job-candidature .contact-content > div:first-child {
        margin-right: 32px;
        min-width: 200px; }
      .jobs-field .job-candidature .contact-content p, .jobs-field .job-candidature .contact-content a {
        margin: 0;
        line-height: 1.8; }
    .jobs-field .job-candidature a,
    .jobs-field .job-candidature .contact p {
      font-size: 14px; }

@media only screen and (max-width: 992px) {
  .jobs-field {
    flex-flow: row wrap; }
    .jobs-field h3 {
      margin-bottom: 24px; }
    .jobs-field > div {
      flex: 1 100%; }
      .jobs-field > div:first-child {
        margin: 0 0 40px 0; }
    .jobs-field .job-candidature .candidature {
      padding-bottom: 40px;
      margin-bottom: 40px; } }

@media only screen and (max-width: 576px) {
  .jobs-field {
    padding-top: 40px;
    padding-bottom: 40px; }
    .jobs-field .job-candidature .candidature {
      padding-bottom: 24px;
      margin-bottom: 24px; }
      .jobs-field .job-candidature .candidature a {
        margin-top: 24px; }
    .jobs-field .job-candidature .contact-content {
      flex-flow: column; }
      .jobs-field .job-candidature .contact-content > div:first-child {
        margin: 0 0 16px 0; } }

.rims-list {
  display: flex;
  list-style: none;
  gap: 2rem; }

.rim h3 {
  text-align: center; }

/* Grid
--------------------------------------------- */
/*!
 * Bootstrap Grid v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  box-sizing: inherit; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }
