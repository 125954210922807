//#branches-app {
//  max-width: 1920px;
//  margin: 0 auto;
//  height: calc(100vh - 153px);
//  min-height: 500px;
//}
//
//@media only screen and (max-width: 992px) {
//  #branches-app {
//    height: calc(100vh - 92px);
//  }
//}
//
//@media only screen and (max-width: 576px) {
//  #branches-app {
//    height: auto;
//  }
//}

@import "../base/color";
@import "../base/variables";

.contact-page {
  padding-top: ($size-extra-big + $size-double);

  /* Text fields */
  h1 {
    text-align: center;
    margin-bottom: $size-extra-big;
  }

  .text-columns,
  .form-columns {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: $size-extra-big;

    > div {
      flex: 1 40%;

      &:last-child {
        margin-left: $size-extra-big*1.5;
      }
    }
  }

  .text-columns {
    margin-bottom: $size-extra-big;

    > div p {
      text-align: justify;
      line-height: 1.65;
      font-size: 15px;

      &:last-child {
        margin-bottom: 0;
      }

    }
  }

  /* Contact form */
  .contact-form {
    padding-bottom: ($size-extra-big + $size-double);

    .form-columns,
    .form-columns > div > div:not(:last-child) {
      margin-bottom: $size-half * 1.5;
    }

    label:not(.accept) {
      color: $color-gray-text-heading;
      text-transform: uppercase;
      padding: 0 $size;
      font-size: 13px;
    }

    label.error {
      color: $color-red
    }

    .inputContainer {
      position: relative;

      .errorContainer {
        position: absolute;
        top: 30%;
        right: 20px;

        label {
          color: $color-red
        }
      }
    }

    input:focus {
      outline: none !important;
      border-color: $color-tertiary !important;
      border-style: solid !important;
    }
    input:not(.submit-btn):not(.hidden-checkbox),
    textarea {
      border: 2px solid $color-gray-border;
      border-radius: 8px;
      width: 100%;
      margin-top: $size-small;
      padding: $size-half $size;

      &:focus,
      &:focus-visible,
      &:hover {
        outline: none !important;
        border-color: $color-tertiary !important;
        border-style: solid !important;
      }

      &.error {
        border-color: $color-red;
        background-color: rgba($color-red, .2);
      }


    }

    .accept-submit {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      margin-top: $size-big;
      margin-bottom: 0;

      .accept {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 $size 0 0;
        position: relative;

        .hidden-checkbox {
          opacity: 0;
        }

        .custom-checkbox {
          position: absolute;
          top: 2px;
          left: 0;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid $color-tertiary;
          cursor: pointer;

          &::after {
            position: absolute;
            content: "";
            left: 2px;
            top: 2px;
            height: 8px;
            width: 8px;
            border-radius: 50%;
          }
        }

        .hidden-checkbox:checked ~ .custom-checkbox::after {
          background-color: $color-tertiary;
        }

        .text {
          font-size: 12px;
          margin-left: $size-small;

          a {
            font-weight: 700;
            color: $color-secondary;
            text-decoration: underline;

            &:hover {
              text-decoration: none;
            }
          }
        }
      }

      .submit-btn {
        font-weight: 700;
      }
    }
  }

  /* Contacts info and Map */
  .contacts-map {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: space-between;
    background-color: $color-tertiary;

    > div:first-of-type {
      flex: 1 20%;
      padding: $size-extra-big;
      margin: 0 auto;
      color: $color-secondary;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: center;

      .logo {
        margin-bottom: $size-big;
      }

      .contact-info {
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        justify-content: flex-start;
        min-height: 25px;

        &:not(:last-child) {
          margin-bottom: $size;
        }

        img {
          margin-right: $size-double;
        }

        p {
          margin-bottom: 0;
        }

        a {
          color: $color-secondary;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    > div:last-of-type {
      flex: 1 80%;
      width: 80%;
    }
  }
}

@media only screen and (max-width: 992px) {
  .contact-page {
    padding-top: $size-extra-big;

    /* Contact form */
    .contact-form {
      padding-bottom: $size-extra-big;
    }

    /* Contacts info and Map */
    .contacts-map > div:first-of-type {
      padding: $size-big;
    }
  }
}

@media only screen and (max-width: 768px) {
  .contact-page {
    /* Text fields */
    h1 {
      margin-bottom: $size-extra-big;
    }

    .text-columns {
      flex-flow: column;
      margin-bottom: $size-extra-big;

      > div {
        flex: 1 100%;
        width: 100%;

        &:last-child {
          margin: $size-half 0 0 0;
        }
      }
    }

    /* Contact form */
    .form-columns > div:last-child {
      margin-left: $size-big;
    }

    /* Contacts info and Map */
    .contacts-map {
      flex-flow: column;
      align-items: center;

      > div:first-of-type,
      > div:last-of-type {
        flex: 1 100%;
      }

      > div:last-of-type {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 576px) {
  .contact-page {
    /* Text fields */
    h1,
    .text-columns,
    .form-columns {
      margin-bottom: $size-double;
    }

    .form-columns {
      flex-flow: column;

      > div {
        flex: 1 100%;
        width: 100%;

        &:last-child {
          margin: $size-half * 1.5 0 0 0;
        }

        > div:not(:last-child) {
          margin: 0 0 $size-half * 1.5 0;
        }
      }
    }

    /* Contact form */
    .contact-form .accept-submit {
      flex-flow: column;
      align-items: center;
      justify-content: space-between;
      margin-top: $size-double;
      margin-bottom: 0;

      label {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin: 0 0 $size;
      }
    }
  }
}
