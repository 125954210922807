@import "../base/color";
@import "../base/variables";

table {
  width: 100%;
  word-break: break-word;
  table-layout: fixed;

  tr:first-child th {
    border-top: 1px solid $color-secondary;
  }

  tr:not(:last-child) th,
  tr:not(:last-child) td{
    border-bottom: 1px solid $color-gray-border;
  }

  tr {
    th,
    td {
      padding: $size $size-half;
      line-height: initial;
      text-align: left;

      p:last-child {
        margin-bottom: 0;
      }
    }

    th {
      font-size: 14px;
      color: $color-secondary-opacity;

      &:not(:last-child) {
        border-right: 1px solid $color-gray-border;
      }
    }

    td:not(:last-child) {
      border-right: 1px solid $color-gray-border;
    }
  }
}

@media only screen and (max-width: 576px) {
  table tr {
    th,
    td {
      padding: $size-half $size-small;
    }
  }
}
