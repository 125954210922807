$color-primary: #ffffff;
$color-primary-opacity: rgba(255, 255, 255, 0.5);
$color-secondary: #000000;
$color-secondary-opacity: rgba(0, 0, 0, 0.5);
$color-tertiary: #ffed00;
$color-blue: #08315a;
$color-red: #fc5f20;
$color-gray-light: #f0f0f0;
$color-gray-border: #d8d8d8;
$color-gray-background: #efefef;
$color-gray-background-two: #f6f6f6;
$color-gray-text-heading: #a4a4a4;
$color-light-blue: #3ddbe0;
$color-grey-border-2: #dedede;
