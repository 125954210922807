@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* Design images*/
    .design-images .img-btn {
        position: relative;
        flex: 50%;
        width: 50%;

        &:hover {
            flex: 50% !important;
        }
    }

    /* Youtube video */
    .youtube-video {
       height: 600px !important;
    }
}
